import Styles from "./OneOffer.module.scss";
import {showTonConnectLoginPage, useUserAddress} from "../../../tonConnect.ts";
import Button from "../../buttons/Button.tsx";
import {Address, fromNano} from "@ton/core";
import Styles2 from "./OffersAll.module.scss";
import FormatAddress from "../../utils/FormatAddress.tsx";
import {getTonViewerLink, IJetton} from "../../../config.ts";
import TonConnectBtn from "../../buttons/TonConnectBtn.tsx";
import {OfferLoan} from "../../../contracts/Contracts/tact_LoanContract.ts";
import {fromNanoDigits} from "../../../contracts/utils.ts";

export default function OneOffer(
    {offer, cancelOffer, cancelable, acceptOffer, acceptable, jetton}: {
        offer: OfferLoan,
        cancelOffer: () => unknown,
        cancelable: boolean,
        acceptable: boolean,
        acceptOffer: () => unknown,
        jetton: IJetton,
    }) {
    const userAddress = useUserAddress();
    return (
        <div class={Styles.offer + ' ' + Styles2.list + ' mono'}>
            <div class={Styles.ton}>{fromNanoDigits(offer.offer.wantAmount, jetton.decimals)} {jetton.title}</div>
            <div
                class={Styles.apr}>{(Number(100_00n * (offer.offer.dayInterest) / (offer.offer.wantAmount)) / 100).toFixed(2)} %
            </div>
            <div class={Styles.days}>{offer.offer.days} day{offer.offer.days !== 1n ? 's' : ''}</div>
            <div
                class={Styles.repayment}>{fromNanoDigits(offer.offer.dayInterest * offer.offer.days, jetton.decimals)} {jetton.title}</div>
            <div class={Styles.lender}>
                <FormatAddress address={offer.owner.toString()} length={8}
                               href={getTonViewerLink(offer.owner.toString())}/>
            </div>
            <div class={Styles.actionBtns}>
                {!userAddress && <TonConnectBtn onClick={() => showTonConnectLoginPage()}/>}
                {cancelable && offer.owner.equals(Address.parse(userAddress!)) &&
                    <Button onClick={cancelOffer}>Cancel</Button>}
                {acceptable && <Button onClick={acceptOffer}>Accept</Button>}
            </div>
        </div>
    )
}