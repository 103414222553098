import {Address} from "@ton/core";

export interface LoanNFT {
    title: string;
    description: string;
    image: string;
    link: string;
    want_amount_ton: number;
    want_interest: number;
    want_period_days: number;
    id: string;
}


export const API_URL = "http://localhost:3000/api"


export function nftLink(address: string) {
    address = Address.parse(address).toString();
    if (import.meta.env.VITE_NETWORK === 'testnet')
        return "https://testnet.getgems.io/collection/1/" + address;
    return "https://getgems.io/collection/1/" + address;
}

export function getTonViewerLink(address: string | Address) {
    address = address instanceof Address ? address : Address.parse(address).toString();
    if (import.meta.env.VITE_NETWORK === 'testnet')
        return "https://testnet.tonviewer.com/" + address;
    return "https://tonviewer.com/" + address;
}

export function getGetgemsLink(address: string, collection: boolean = false) {
    address = Address.parse(address).toString();
    const testnet = import.meta.env.VITE_NETWORK === 'testnet'?'testnet.': '';
    if (!collection) {
        return `https://${testnet}getgems.io/collection/1/` + address;
    } else {
        return `https://${testnet}getgems.io/collection/${address}`;
    }
}

export function getTonScanLink(address: string) {
    address = Address.parse(address).toString();
    if (import.meta.env.VITE_NETWORK === 'testnet')
        return "https://testnet.tonscan.org/address/" + address;
    return "https://tonscan.org/address/" + address;
}

export interface IJetton {
    title: string;
    decimals: number;
    icon: string;
    address: Address | null;
}

export const DefaultJettons: IJetton[] = [
    {
        title: 'TON',
        icon: 'https://ton.org/icons/custom/ton_logo.svg',
        address: null,
        decimals: 9
    },
    (
        import.meta.env.VITE_NETWORK === 'testnet' ? (
            {
                title: 'AIR',
                icon: 'https://testnet.tonviewer.com/assets/images/stub-image-black.png',
                address: Address.parse('kQAp_H-fVRrcAhNS7LaXGQ4GsP_yBQT98t0kwNohtaUjLg7r'),
                decimals: 9
            }
        ) : ({
                title: 'USDT',
                icon: 'https://cache.tonapi.io/imgproxy/T3PB4s7oprNVaJkwqbGg54nexKE0zzKhcrPv8jcWYzU/rs:fill:200:200:1/g:no/aHR0cHM6Ly90ZXRoZXIudG8vaW1hZ2VzL2xvZ29DaXJjbGUucG5n.webp',
                address: Address.parse('EQCxE6mUtQJKFnGfaROTKOt1lZbDiiX1kCixRv7Nw2Id_sDs'),
                decimals: 6
            }
        )
    ),
    ...(import.meta.env.VITE_NETWORK === 'testnet' ? [{
        title: 'LOM',
        icon: 'https://cache.tonapi.io/imgproxy/kQsktET2A2ITMzAqQSfylnyRCFxUR_6OhtcQDVNH7R0/rs:fill:200:200:1/g:no/aHR0cHM6Ly9hdmF0YXJmaWxlcy5hbHBoYWNvZGVycy5jb20vMzY3LzM2NzIxNy5qcGc.webp',
        address: Address.parse('kQC6cYfMFYFur2IgJroc3wBxg-q4hOxsqGQwEYSEARxtOmZf'),
        decimals: 5
    }] : [])
]


//jetton for test - kQAYX4gVF__o3DIEdpaQB641gEDktCa4Q4kZpzWak_KN-15E




