import {useEffect} from "preact/hooks";
import {Main} from "./Main.tsx";
import {useParams} from "react-router-dom";
import {ToastContainer} from "react-toastify";

export default function Page(props: { title?: string, children: any }) {
    const params = useParams();
    useEffect(() => {
        let t = props.title || "";
        //replace each $param.* with the actual value from the url
        t = t.replace(/\$param\.([a-zA-Z0-9]+)/g, (_, p1) => {
            return params[p1] || "";
        })
        document.title = t;

    }, [props.title]);
    return <Main>
        {props.children}

        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable={true}
            pauseOnHover={false}
            theme="dark"
        />
    </Main>;
};