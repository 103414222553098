import {useEffect, useState} from "preact/hooks";
import Styles from "./TimeCounter.module.scss";
export function TimeCounter({till}: {till: Date}){
    const [now, setNow] = useState(new Date());

    useEffect(() => {
        const interval = setInterval(() => {
            setNow(new Date());
        }, 1000);
        return () => clearInterval(interval);
    }, [till]);
    const deltaDateSeconds = (+till - +now)/1000;

    const days = Math.floor(+deltaDateSeconds / 3600 / 24)>0 ? <>{Math.floor(+deltaDateSeconds / 3600 / 24)} days</>:<></>;
    return (
        <span class={Styles.timeCounter}>
            {days} {Math.floor((deltaDateSeconds/ 3600)%24).toString().padStart(2, '0')}:{Math.floor((deltaDateSeconds/60)%60).toString().padStart(2, '0')}:{Math.floor(deltaDateSeconds%60).toString().padStart(2, '0')}
        </span>
    )

}