import {DefaultJettons, IJetton} from "../../../config.ts";
import {useEffect, useRef, useState} from "preact/hooks";
import Styles from "./DataBlockSetJettons.module.scss"
import BackIcon from "../../Icons.tsx";
import {Address} from "@ton/core";
import InputNumber from "rc-input-number";
import {createRef} from "preact/compat";
import {getJettonData} from "../../../contracts/main.ts";
import Button from "../../buttons/Button.tsx";

export default function SetJettonsState({onCancel, onSuccess, jettonsStart}: {
    onCancel: () => unknown,
    onSuccess: (jettons: (Address | null)[]) => unknown,
    jettonsStart: IJetton[]
}) {
    const [jettons, setJettons] = useState<IJetton[]>(jettonsStart);
    const input = createRef<HTMLInputElement>();
    const [selectedAddresses, setSelectedAddresses] = useState(new Set<string | null>());
    return (
        <div class={Styles.containerMain}>
            <div class={Styles.flexBack}>
                <div onClick={onCancel} style={{textAlign: 'left', width: '40px'}}><BackIcon size={25}/></div>
                <h4>Set acceptable jettons for offers</h4>
            </div>
            <div class={Styles.jettonContainer}>
                {jettons.map(jet =>
                    <div
                        class={Styles.jetton + (selectedAddresses.has(jet.address?.toString() ?? '') ? ' ' + Styles.selected : '')}
                        onClick={() => setSelectedAddresses(addToSet(selectedAddresses, jet.address?.toString() ?? ''))}>
                        <img src={jet.icon} width={16} height={16}/> {jet.title}
                    </div>
                )}

                <div class={Styles.jetton}>
                    <input type="text" ref={input} class={`rc-input-number ${Styles.otherJetton}`}
                           placeholder={' Enter address...'}/>
                    <button tabindex={0} class={Styles.plus} onClick={addAddress}>+</button>
                </div>
            </div>

            <Button disabled={selectedAddresses.size === 0}
                    onClick={() => onSuccess(Array.from(selectedAddresses).map(e => e ? Address.parse(e) : null))}>Set
                available jettons</Button>
        </div>
    );


    async function addAddress() {
        const valueNow = input.current!.value;
        input.current!.value = '';
        const probablyToken = await getJettonData(Address.parse(valueNow));
        if (jettons.some(e => (e.address)?.equals(probablyToken.address!))) {
            console.warn("Already in list!");
            return;
        }
        setJettons([...jettons, probablyToken]);
        setSelectedAddresses(addToSet(selectedAddresses, probablyToken.address!.toString()))
    }

}

function addToSet<T>(set: Set<T>, value: T) {
    set = new Set(set);
    if (set.has(value)) set.delete(value);
    else set.add(value);
    return set;
}