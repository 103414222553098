import InputNumber from "rc-input-number";
import Styles from "./InputStyles.module.scss";
import {useCallback, useEffect, useRef} from "preact/hooks";
import useHandleTabButton from "../utils/handleTabButton.ts";

export default function ARPInput({onChange, placeholder = 'Max ARP', class: className, def, onTabPress, visible}: {
    onChange: (v: number | null) => void,
    placeholder?: string,
    class?: string,
    def?: number,
    onTabPress?: () => unknown,
    visible?: boolean
}) {
    const reference = useRef(null);
    useHandleTabButton((e: KeyboardEvent) => {
        if (!visible || !onTabPress) return;
        onTabPress?.();
        e.preventDefault();
    }, [visible, onTabPress]);
    useEffect(() => {
        if (visible) (reference.current! as HTMLInputElement).focus();
    }, [visible]);
    return (
        <InputNumber
            ref={reference}
            onPressEnter={() => {
                console.log("ABOBA")
            }}
            changeOnWheel={true}
            defaultValue={def}
            min={0.5}
            max={1000000}
            step={0.1}
            className={className}
            placeholder={placeholder}
            precision={2}
            prefix={<div class={Styles.prefix}>%</div>}
            onChange={onChange}/>

    )
}