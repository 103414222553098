import {ReactNode} from "preact/compat";
import Styles from "./FadeOutBlock.module.scss"
import {useRef, useState} from "preact/hooks";

export default function FadeOutBlock({children, title, opened, onOpen, tabIndex}: {
    title: ReactNode,
    children: ReactNode,
    opened: boolean,
    onOpen: (now: boolean) => unknown,
    tabIndex?: number,
    onFocus?: (e: FocusEvent) => unknown
}) {
    const ref = useRef<HTMLDivElement>(null);
    return (
        <div class={Styles.fadeOutBlock + " " + (opened ? Styles.opened : '')} tabIndex={tabIndex} ref={ref}>
            <div class={Styles.fadeOutTitle} onClick={() => onOpen(!opened)}>
                {title}
            </div>
            <div class={Styles.fadeOutContent}>
                {children}
            </div>
        </div>
    )
}

