import {useNavigate} from "react-router-dom";


export default function NavigateLink({ href, children, class: className }: {href: string, children: any, class?: string}) {
    const navigate = useNavigate();

    return (
        <a href={href} class={className} onClick={e=>{
            navigate(href);
            e.preventDefault();
        }}>
            {children}

        </a>
    );
}

