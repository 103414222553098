

export default function ImageIcon({image, href, alt}: {
    image: string;
    alt: string,
    href: string;
}) {
    return (
        <a href={href} target="_blank" style={{marginRight: "10px"}}>
            <img src={image} width={42} height={42} alt={alt}/>
        </a>
    )
}