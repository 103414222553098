import Content from "../components/utils/Content.module.tsx";

export default function Home() {

    return (
        <Content>
            <div style={{display: "flex", gap: "10px"}}>
                This is home page!
                Use the navigation bar to navigate to other pages.
            </div>
        </Content>
    )
}
