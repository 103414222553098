import {useParams} from "react-router-dom";
import {useEffect, useRef, useState} from "preact/hooks";
import {Address, Dictionary, fromNano, toNano} from "@ton/core";
import Styles from "./Loan.module.scss";
import LoadingSpinner from "../../components/utils/LoadingSpinner.tsx";
import {TonApiOneNFT} from "../../api/tonapi/interfaces.ts";
import TonApi from "../../api/tonapi/TonApi.ts";
import {
    cancelTheLoan, DefAddress, getJettonData,
    giveLoan,
    repayLoan, sendOffer, setJettons,
    tryGetData,
    tryGetLoanOffers,
    withdrawNftNotRepayed
} from "../../contracts/main.ts";
import DataBlockImage from "../../components/NftDataLoan/DataBlocks/DataBlockImage.tsx";
import DataBlockInformation from "../../components/NftDataLoan/DataBlocks/DataBlockInformation.tsx";
import {IJetton, nftLink} from "../../config.ts";
import DataBlockActions from "../../components/NftDataLoan/DataBlocks/DataBlockActions.tsx";
import WarningMessage from "../../components/utils/WarningMessage.tsx";
import {InputNewOfferData} from "../../components/NftDataLoan/MakeOffer/MakeOfferData.tsx";
import {
    showApproveDialog,
    showApproveDialogLoading
} from "../../components/ApproveTransactionDialog/ApproveTransactionDialog.tsx";
import LoanOffers from "../../components/NftDataLoan/LoanOffers/LoanOffers.tsx";
import Content, {Data} from "../../components/utils/Content.module.tsx";
import {OfferLoan} from "../../contracts/Contracts/tact_LoanContract.ts";
import {useUserAddress} from "../../tonConnect.ts";
import {toNanoDigits} from "../../contracts/utils.ts";

export interface LoanState {
    started: boolean;
    stopped: boolean;
    startTime: Date;
    issuer: Address | null;
    needToRepayInterest: bigint;
    owner: Address;
    acceptedJettons: { [k: string]: Address }
}

export type LoanConditions = {
    wantAmount: bigint;
    days: bigint;
    dayInterest: bigint;
    jetton: IJetton
};
export default function Loan() {
    const {loan: loanId} = useParams();
    const [loanConditions, setLoanConditions] = useState<LoanConditions | null>();
    const [data, setData] = useState<LoanState>({
        startTime: new Date(),
        started: false,
        stopped: false,
        issuer: null!,
        owner: null!,
        needToRepayInterest: 0n,
        acceptedJettons: {}
    });
    const [nft, setNft] = useState<TonApiOneNFT | null>();
    const [error, setError] = useState<Error | null>();
    const [offers, setOffers] = useState<{ [k: number]: OfferLoan }>({});
    const [allOfferJettons, setAllOfferJettons] = useState<IJetton[]>([])
    const dataRef = useRef<LoanState>();
    const currentUserAddress = useUserAddress();
    dataRef.current = data;
    useEffect(() => {
        if (loanConditions != null) return;
        loadData();
        parseOffers(() => tryGetLoanOffers(Address.parse(loanId!))).then(setOffers).catch(setError);
    }, []);

    if (error) {
        console.error(error);
        return <div>Error: {error.message}</div>;
    }
    if (!nft || !loanConditions) return <div style={{textAlign: 'center'}}><LoadingSpinner size={70} color={"#fff"}/>
    </div>
    return (
        <Content>
            {data.stopped && <WarningMessage>Loan is no more active!</WarningMessage>}
            {Object.keys(data.acceptedJettons).length === 0 && <WarningMessage>Loan is not active yet! {
                !!currentUserAddress && data.owner.equals(Address.parse(currentUserAddress)) && <><br/> Activate it by
                    clicking "activate" button on the right panel.</>
            }</WarningMessage>}
            <Data>
                <DataBlockImage src={nft?.previews?.[2]?.url ?? ""} alt={nft?.metadata?.name ?? ''}
                                onRefresh={loadData} nftAddress={nft.address} loanAddress={loanId!}/>
                <DataBlockInformation
                    title={nft!.metadata!.name}
                    collection={nft.collection?.name ?? ""}
                    collectionHref={nftLink(nft.address)}
                    loanData={loanConditions!}
                    started={data.started}
                />
                <DataBlockActions
                    jettons={allOfferJettons}
                    hasJettons={Object.keys(data.acceptedJettons).length > 0}
                    onSetJettons={setJettonsIT}
                    state={data} onCancelLoan={cancel} onNewOffer={createNewOffer} onRepay={repay}
                    onAcceptDefault={giveLoanIt} onNotRepayed={withdrawNftNotPayed}
                    conditions={loanConditions}
                    defaultOfferData={{
                        wantAmount: +fromNano(loanConditions.wantAmount),
                        arp: +fromNano(loanConditions.dayInterest) / +fromNano(loanConditions.wantAmount),
                        loanDurationDays: Number(loanConditions.days),
                        jetton: allOfferJettons[0]
                    }}
                />
            </Data>
            <LoanOffers
                jettons={allOfferJettons}
                offers={offers}
                loanAddress={loanId!}
                updateOffers={async () => {
                    parseOffers(() => tryGetLoanOffers(Address.parse(loanId!))).then(setOffers).catch(setError);
                }}
                loanOwner={data.owner}
                started={data.started}
            />
        </Content>
    );

    async function setJettonsIT(jettons: (Address | null)[]) {
        if (!jettons.some(e => {
            const n = loanConditions!.jetton.address;
            if (e === null && n === null) return true;
            if ((e !== null && n === null) || (e === null && n !== null)) return false;
            return e!.equals(n!);
        })) {
            jettons = [...jettons, loanConditions!.jetton.address]
            return;
        }
        await showApproveDialog((async () => {
            await setJettons(Address.parse(loanId!), jettons)
        }));
        await showApproveDialogLoading(async () => {
            for (let i = 0; i < 15; i++) {
                await new Promise(r => setTimeout(r, 10_000));
                await loadData();
                if (Object.keys(dataRef.current!.acceptedJettons).length !== 0) break;
            }
            throw new Error("Jettons not added...")
        })
    }

    async function createNewOffer(offer: InputNewOfferData) {
        try {
            await showApproveDialog((async () => {
                let decimals = loanConditions!.jetton.decimals;
                await sendOffer(Address.parse(loanId!), {
                    days: BigInt(offer.loanDurationDays),
                    wantAmount: toNanoDigits(offer.wantAmount.toFixed(decimals), decimals),
                    dayInterest: toNanoDigits((offer.arp * offer.wantAmount).toFixed(decimals), decimals),
                    jetton: (offer.jetton.address),
                    $$type: 'Loan'
                });
            }));
            await showApproveDialogLoading(async () => {
                const loanOffersOld = Object.keys(offers);
                for (let i = 0; i < 15; i++) {
                    await new Promise(e => setTimeout(e, 10_000));
                    const offersNow = await parseOffers(() => tryGetLoanOffers(Address.parse(loanId!)))
                    setOffers(offersNow);
                    if (Object.keys(offersNow).length !== loanOffersOld.length) return;
                }
                throw new Error("Offer not added (for some reason)");
            });
        } catch (e) {
            console.error(e);
        }
    }

    async function cancel() {
        try {
            await showApproveDialog(cancelTheLoan(Address.parse(loanId!)));
            await showApproveDialogLoading(async () => {
                for (let i = 0; i < 15; i++) {
                    await new Promise(e => setTimeout(e, 10_000));
                    await loadData();
                    if (dataRef.current!.stopped) return;
                }
                throw new Error("Loan not canceled (for some reason)");
            });
        } catch (e) {
            console.error(e);
        }
    }

    async function giveLoanIt() {
        try {
            await showApproveDialog(giveLoan(Address.parse(loanId!)));
            await showApproveDialogLoading(async () => {
                for (let i = 0; i < 15; i++) {
                    await new Promise(e => setTimeout(e, 10_000));
                    await loadData();
                    if (dataRef.current!.started) return;
                }
                throw new Error("Loan not started (for some reason)");

            })
        } catch (e) {
            console.error(e);
        }
    }

    async function repay() {
        try {
            await showApproveDialog(() => repayLoan(Address.parse(loanId!)));
            await showApproveDialogLoading(async () => {
                for (let i = 0; i < 10; i++) {
                    await new Promise(e => setTimeout(e, 10_000));
                    await loadData();
                    if (dataRef.current!.stopped) return;
                }
                throw new Error("Loan not repaid (for some reason)");
            });
        } catch (e) {
            console.error(e);
        }
    }


    async function loadData() {
        try {
            setLoanConditions(null);
            setNft(null);
            const data = await tryGetData(Address.parse(loanId!));
            const [nft, jettonData, ...jettons]: [TonApiOneNFT, IJetton, ...IJetton[]] = await Promise.all([
                TonApi.getNFTById(data.nft!.toString()),
                getJettonData(data.activeLoan.jetton),
                ...data.acceptJettons.values().map(getJettonData)
            ]);

            setAllOfferJettons(jettons);

            setLoanConditions({
                jetton: jettonData,
                days: data.activeLoan.days,
                wantAmount: data.activeLoan.wantAmount,
                dayInterest: data.activeLoan.dayInterest
            } as LoanConditions);
            setNft(nft);
            setData({
                issuer: data.loanIssuer,
                stopped: data.stopped,
                started: data.started,
                startTime: new Date(Number(data.startTime) * 1000),
                needToRepayInterest: data.accuredInterest,
                owner: data.owner,
                acceptedJettons: Object.fromEntries(data.acceptJettons.keys().map(e => [e.toString(), data.acceptJettons.get(e)!]))
            });
        } catch (e: unknown) {
            setError(e as Error);
        }
    }

    async function withdrawNftNotPayed() {
        try {
            await showApproveDialog(withdrawNftNotRepayed(Address.parse(loanId!)));
            await showApproveDialogLoading(async () => {
                for (let i = 0; i < 10; i++) {
                    await new Promise(e => setTimeout(e, 10_000));
                    await loadData();
                    if (dataRef.current!.stopped) return;
                }
                throw new Error("NFT not withdrawn (for some reason)");
            });
        } catch (e) {
            console.error(e);
        }
    }
}

async function parseOffers(tryGetLoanOffers: () => Promise<Dictionary<number, OfferLoan>>) {
    const offers = await tryGetLoanOffers();
    const offersDict: { [k: number]: OfferLoan } = {};
    for (const [id, offer] of offers) {
        offersDict[id] = offer;
    }

    return offersDict;
}
