import "./buffer.tsx";
import "./ton_tvm_hack.ts"
import {render} from 'preact'
import Home from './routes/home.tsx'
import './index.scss'
import {
    createBrowserRouter,
    Route,
    createRoutesFromElements, RouterProvider,
} from "react-router-dom";
import ClientPage from "./routes/client";
import JewelerPage from "./routes/jeweler";
import Page from "./routes/Page.tsx";
import PawnNftPage from "./routes/client/PawnNftPage.tsx";
import Loan from "./routes/client/Loan.tsx";
import "./NumberInput.scss";
import AdminPage from "./routes/Admin.tsx";

const routes = (
    <>
        <Route path="/" element={<Page title="Home"><Home/></Page>}/>
        <Route path="jeweler" element={<Page title="Jeweler"><JewelerPage/></Page>}/>
        <Route path="client/nft/:nft/pawn" element={<Page title={"Pawn nft $param.nft"}><PawnNftPage/></Page>}/>
        <Route path="client" element={<Page title="Client"><ClientPage/></Page>}/>
        <Route path="admin" element={<Page title="Admin"><AdminPage/></Page>}/>
        <Route path="loan/:loan" element={<Page title={"Loan $param.loan"}><Loan/></Page>}/>
    </>
)
render(
    <RouterProvider router={
        createBrowserRouter(
            createRoutesFromElements(routes)
        )
    }/>,
    document.getElementById('app')!
);
document.getElementById('loading')?.remove();

