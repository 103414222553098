import {NftData} from "../../routes/jeweler";
import Styles from "./NftMetadata.module.scss";
import StylesMain from "./JewelerNFT.module.scss";
import {DefaultJettons, IJetton} from "../../config.ts";
import {Address} from "@ton/core";
import {DefAddress} from "../../contracts/main.ts";
import {fromNanoDigits} from "../../contracts/utils.ts";
import {Link} from "react-router-dom";

interface NftMetadataProps {
    nft?: NftData
}

export default function NftMetadata({nft}: NftMetadataProps) {
    const jetton = nft?.activeLoan_jetton
    const {decimals, icon, title} = parseDataJetton(jetton);
    return (
        <>
            <div className={Styles.metadata + ' ' + StylesMain.padding}>
                <div class={Styles.item}>
                    <div class={Styles.title}>Amount</div>
                    <div class={Styles.value}>{
                        (+fromNanoDigits(BigInt(nft?.activeLoan_wantAmount ?? 0n), decimals)).toFixed(2)
                    }</div>
                </div>
                <div class={Styles.item}>
                    <div class={Styles.title}>Coin</div>
                    <div class={Styles.value}><img src={icon} width={16} height={16}/> {title}</div>
                </div>

            </div>
            <Link to={"/loan/" + (nft?.loan ? Address.parse(nft!.loan) : '0')} class={Styles.link}>
                <img src={nft!.nft_item.previews[2].url} alt={nft!.nft_item.metadata?.name} className={Styles.image}/>
            </Link>
            <div className={Styles.metadata + ' ' + StylesMain.padding}>
                <div className={Styles.item}>
                    <div className={Styles.title}>Days</div>
                    <div className={Styles.value}>{nft?.activeLoan_days}d</div>
                </div>
                <div className={Styles.item}>
                    <div className={Styles.title}>Daily ARP</div>
                    <div
                        className={Styles.value}>{(+fromNanoDigits(BigInt(nft?.activeLoan_dayInterest ?? 0n), decimals) * 100).toFixed(2)}%
                    </div>
                </div>
            </div>
        </>
    )
}

export function parseDataJetton(jetton: string | undefined | null): IJetton {
    if (!jetton) return DefaultJettons[0];
    const addr = Address.parse(jetton);
    if (addr.equals(DefAddress)) return DefaultJettons[0];
    const jettonData = DefaultJettons.find(e => e.address && e.address.equals(addr));
    if (!jettonData) return {decimals: 9, title: 'Unknown', address: null, icon: ''};
    return DefaultJettons[0]
}

