import InputNumber from "rc-input-number";
import Styles from "./InputStyles.module.scss";
import useHandleTabButton from "../utils/handleTabButton.ts";
import {useCallback, useEffect, useRef} from "preact/hooks";


export default function DurationInput(
    {
        onChange,
        placeholder = 'Loan duration',
        class: className,
        default: def,
        onTabPress,
        visible
    }: {
        onChange: (v: number | null) => void,
        placeholder?: string,
        class?: string,
        default?: number,
        onTabPress?: () => unknown,
        visible?: boolean
    }) {
    const reference = useRef(null);
    useEffect(() => {
        if (visible) (reference.current! as HTMLInputElement).focus();
    }, [visible]);

    useHandleTabButton((e: KeyboardEvent)=>{
        if(!visible || !onTabPress)return;
        onTabPress?.();
        e.preventDefault();
    }, [visible, onTabPress]);
    return (
        <InputNumber max={1000000} min={1} step={1} precision={0} placeholder={placeholder} ref={reference}
                     onChange={onChange}
                     className={className}
                     defaultValue={def}
                     prefix={
                         <div class={Styles.prefix}>
                             <svg xmlns="http://www.w3.org/2000/svg" height="25px" viewBox="0 -960 960 960"
                                  width="22px">
                                 <path
                                     d="M200-80q-33 0-56.5-23.5T120-160v-560q0-33 23.5-56.5T200-800h40v-80h80v80h320v-80h80v80h40q33 0 56.5 23.5T840-720v560q0 33-23.5 56.5T760-80H200Zm0-80h560v-400H200v400Zm0-480h560v-80H200v80Zm0 0v-80 80Zm280 240q-17 0-28.5-11.5T440-440q0-17 11.5-28.5T480-480q17 0 28.5 11.5T520-440q0 17-11.5 28.5T480-400Zm-160 0q-17 0-28.5-11.5T280-440q0-17 11.5-28.5T320-480q17 0 28.5 11.5T360-440q0 17-11.5 28.5T320-400Zm320 0q-17 0-28.5-11.5T600-440q0-17 11.5-28.5T640-480q17 0 28.5 11.5T680-440q0 17-11.5 28.5T640-400ZM480-240q-17 0-28.5-11.5T440-280q0-17 11.5-28.5T480-320q17 0 28.5 11.5T520-280q0 17-11.5 28.5T480-240Zm-160 0q-17 0-28.5-11.5T280-280q0-17 11.5-28.5T320-320q17 0 28.5 11.5T360-280q0 17-11.5 28.5T320-240Zm320 0q-17 0-28.5-11.5T600-280q0-17 11.5-28.5T640-320q17 0 28.5 11.5T680-280q0 17-11.5 28.5T640-240Z"/>
                             </svg>
                         </div>
                     }
        />
    );
}