import {ITonConnect} from "@tonconnect/ui";
import {
    Address,
    beginCell,
    Builder,
    Cell,
    Dictionary,
    fromNano,
    OpenedContract,
    Sender,
    SendMode,
    toNano
} from "@ton/core";
import {SendProviderIT, SendProviderSender} from "./Wrappers.ts";
import {TonClient} from "@ton/ton";
import TonApi from "../api/tonapi/TonApi.ts";
import {Loan, LoanData, Offer, OfferLoan, storeLoan, SuperMaster} from "./Contracts/tact_LoanContract.ts";
import {LoanContract} from "./Contracts/tact_LoanContract.ts";
import {JettonChildWallet, JettonMaster} from "./Contracts/Jetton.ts";
import {IJetton} from "../config.ts";
import {fromNanoDigits, toNanoDigits} from "./utils.ts";

export const DefAddress = Address.parse('0:0000000000000000000000000000000000000000000000000000000000000000');

export function getProviderSender(): Sender {
    const tonConnect: ITonConnect = (window as any).tonConnect as ITonConnect;
    const sendProvider = new SendProviderIT(tonConnect);
    return new SendProviderSender(sendProvider) as Sender;
}

//
// export const provider = new TonClient({
//     endpoint: import.meta.env.VITE_TONCENTER_API_URL!,
//     apiKey: import.meta.env.VITE_TONCENTER_API_KEY
// });
// const platformAddress = Address.parse(import.meta.env.VITE_PLATFORM!);
//
// export async function sendDeployNFTLoan(owner: Address, nft: Address, amount: bigint, loanDuration: bigint, aprAmount: bigint) {
//     const sender = getNetworkSender();
//
//     const master = await openMaster(provider, sender, {
//         owner,
//         nft,
//         amount,
//         loanDuration,
//         aprAmount,
//         jettonWallet: owner,
//         offers: Dictionary.empty(Dictionary.Keys.Address()),
//         platform: platformAddress,
//     });
//     const data = await master.getContractData();
//     if (!data.active)
//         await sendNft(sender, nft, master.address);
//     for (let i = 0; i < 60 * 5; i++) {
//         const data = await master.getContractData();
//         if (data.active) break;
//         console.log("Waiting for contract to be active", data);
//         await new Promise(resolve => setTimeout(resolve, 1000));
//         if(i === 60 * 5 - 1) throw new Error("Contract not activated");
//     }
//     console.log("Contract is active", master.address);
//     return master;
// }
//
// export async function getDeployedNftLoan(owner: Address, nft: Address) {
//     const master = openMasterFromAddress(owner);
//     const opened = provider.open(master);
//     const data = await opened.getContractData();
//     if (!data.nft.equals(nft)) return null;
//     if (!data.active || !data.nft.equals(nft)) return null;
//     return master;
// }
//
function transferMessage(params: {
    queryId?: number,
    newOwner: Address,
    responseTo?: Address,
    forwardAmount?: bigint,
    forwardPayloadFun: (b: Builder) => unknown
}) {
    const dataLoan = new Builder()
        .store(params.forwardPayloadFun)
        .endCell();

    // const dataToTransfer = new Builder().storeRef(dataLoan).endCell();

    // console.log(dataToTransfer.toBoc().toString());

    let msgBody = beginCell()
        .storeUint(0x5fcc3d14, 32)
        .storeUint(0, 64)
        .storeAddress(params.newOwner)
        .storeAddress(params.responseTo || null)
        .storeUint(0, 1)
        .storeCoins(params.forwardAmount ?? toNano('0.1'))
        .storeBit(1)
        .storeRef(dataLoan);
        // .storeSlice(dataToTransfer.asSlice())
    return msgBody.endCell();
}


export const provider = new TonClient({
    endpoint: import.meta.env.VITE_TONCENTER_API_URL!,
    apiKey: import.meta.env.VITE_TONCENTER_API_KEY
});

const MasterContractAddress = Address.parse(import.meta.env.VITE_MASTER_CONTRACT!);

function getMaster(): OpenedContract<SuperMaster> {
    return provider.open(SuperMaster.fromAddress(MasterContractAddress));
}

function getLoanContract(address: Address): OpenedContract<LoanContract> {
    return provider.open(LoanContract.fromAddress(address));
}

// export function tryGetLoan(address: Address) {
//     const loan = getLoanContract(address);
//     return loan.getLoan();
// }

// export function tryGetNftAddress(loanAddress: Address) {
//     const loan = getLoanContract(loanAddress);
//     return loan.getNft();
// }

// export function tryGetLoanOwner(loanAddress: Address) {
//     const loan = getLoanContract(loanAddress);
//     return loan.getOwner();
// }

export function cancelTheLoan(loanAddress: Address) {
    const loan = getLoanContract(loanAddress);
    return loan.send(getProviderSender(), {value: toNano('0.02'), bounce: true}, {
        $$type: 'CancelLoan'
    });
}

export async function repayLoan(loanAddress: Address) {
    const loan = getLoanContract(loanAddress);
    const data = await loan.getData();
    const accuredInterest = data.accuredInterest;
    const l = data.activeLoan;
    if (data.activeLoan.jetton === null) {
        return loan.send(getProviderSender(), {value: toNano('0.05') + accuredInterest + l!.wantAmount}, {
            $$type: 'RedeemMessage',
        });
    } else {
        const {decimals} = await getJettonData(data.activeLoan.jetton);
        await sendJettonWithPayload({
            type: PayloadType.REDEEM,
            jetton: l.jetton!,
            amount: l.wantAmount + accuredInterest,
            loanAddress,
            forwardAmount: toNano('0.22'),
            decimals
        })
    }

}

export function tryGetLoanOffers(loanAddress: Address) {
    const loan = getLoanContract(loanAddress);
    return loan.getOffers();

}

const ParentsALLContracts = new Set<string>(import.meta.env.VITE_ALL_CONTRACTS.split(','));

export async function verifyIsValid(loanAddress: Address) {
    await new Promise(e => setTimeout(e, 2000));
    const loan = getLoanContract(loanAddress);
    const data = await loan.getData();
    const [index, parent] = await Promise.all([data.masterIndex, data.parent]);
    if (!ParentsALLContracts.has(parent.toString())) {
        throw new Error("Invalid parent");
    }
    const parentIt = provider.open(SuperMaster.fromAddress(parent));
    const trueAddress = await parentIt.getAt(index);
    if (!trueAddress.equals(loanAddress)) {
        throw new Error("Invalid index");
    }
}

export async function sendOffer(loanAddress: Address, offer: Omit<Loan, 'bob'>) {
    const sender = getProviderSender();
    const loanCurrent = await getLoanContract(loanAddress).getData();
    await checkJetton(loanAddress, loanCurrent.acceptJettons, offer.jetton);
    if (offer.jetton === null) {
        await getLoanContract(loanAddress).send(sender, {value: offer.wantAmount + toNano('0.02')}, {
            $$type: 'Offer',
            offerLoan: offer
        });
    } else {
        const {decimals} = await getJettonData(offer.jetton);
        await sendJettonWithPayload({
            type: PayloadType.SEND_OFFER,
            forwardAmount: toNano('0.12'),
            loanAddress,
            amount: offer.wantAmount,
            jetton: offer.jetton,
            customRef: beginCell().store(storeLoan(offer)).endCell(),
            decimals
        })
    }
}

export async function setJettons(loanAddress: Address, jettons: (Address | null)[]) {
    const sender = getProviderSender();
    const loan = getLoanContract(loanAddress);
    const addressHelpers: ([Address, Address])[] = await Promise.all(jettons.map(async e => {
        if (e == null) return [
            DefAddress,
            DefAddress
        ];
        const jettonContract = provider.open(JettonMaster.fromAddress(e));
        const ownerAddress = await jettonContract.getGetWalletAddress(loanAddress);
        return [ownerAddress, e];
    }));
    const dictionary = Dictionary.empty<Address, Address>();
    for (const [key, value] of addressHelpers) dictionary.set(key, value);
    await loan.send(sender, {
        value: toNano('0.015'),
        bounce: false
    }, {
        $$type: 'SetAcceptableJettons',
        jettons: dictionary
    })
}

const AllJettonsData = new Map<string, IJetton>();

export async function getJettonData(data: Address | null): Promise<IJetton> {
    if (data == null || data.equals(DefAddress)) return {
        title: 'TON',
        address: null,
        icon: 'https://ton.org/icons/custom/ton_logo.svg',
        decimals: 9
    };
    if (AllJettonsData.has(data.toString())) return AllJettonsData.get(data.toString())!;
    const d = await TonApi.getJettonByAddress(data.toString());
    console.log(d);
    const ans: IJetton = {
        title: d.metadata.symbol,
        address: Address.parse(d.metadata.address),
        icon: d.metadata.image,
        decimals: +d.metadata.decimals
    };
    AllJettonsData.set(data.toString(), ans);
    return ans;

}

export async function getMasterData() {
    const master = getMaster();
    const data = await master.getOwner();
    return {owner: data, address: master.address}
}

/**
 * Checks if one can start loan (loadAddress) with current AcceptJettons dictionary for jetton
 * @param loanAddress current loan address
 * @param acceptJettons dictionary of current loan accepted jettons
 * @param jetton jetton that one wants to create offer/start the loan
 */
async function checkJetton(loanAddress: Address, acceptJettons: Dictionary<Address, Address>, jetton: Address | null) {
    console.log(acceptJettons.values().map(e => e.toString()).join(' '));
    if (jetton === null) {
        if (acceptJettons.get(DefAddress)?.equals(DefAddress)) return true;
        throw new JettonInvalidContractError("Jetton not accepted");
    }
    const q = Array.from(acceptJettons[Symbol.iterator]()).find(([_, master]) => master.equals(jetton));
    if (!q) throw new JettonInvalidContractError("Jetton not accepted");
    const [loanAddressJetton, master] = q;
    const masterJetton = provider.open(JettonMaster.fromAddress(master));
    const needLoanAddressJetton = await masterJetton.getGetWalletAddress(loanAddress);
    if (!needLoanAddressJetton.equals(loanAddressJetton)) {
        throw new JettonInvalidContractError(`Invalid contract jetton address`, loanAddressJetton, needLoanAddressJetton)
    }
}

export class JettonInvalidContractError extends Error {
    constructor(message: string, public readonly addressActual?: Address, public readonly addressNeeded?: Address) {
        super(message);
    }

    toString() {
        let q = `JettonInvalidContractError(message="` + this.message;
        if (this.addressNeeded) {
            q += '", need="' + this.addressNeeded.toString();
        }
        if (this.addressActual) {
            q += '", actual="' + this.addressActual.toString();
        }

        return q + '")';
    }

}

export class LoanInvalidStateError extends Error {

}

export class NotEnoughBalanceError extends Error {
    constructor(message: string, private readonly actualBalance: bigint, private readonly needed: bigint,) {
        super(message)
    }

    toString() {
        return `NotEnoughBalanceError(message="${this.message}", actualBalance=${fromNano(this.actualBalance)}, needed=${fromNano(this.needed)})`;
    }

}

enum PayloadType {
    SEND_OFFER = 1,
    START_JETTON = 2,
    REDEEM = 3,
    REDEEM_NOT_REPAYED = 4
}

async function sendJettonWithPayload(
    {
        type,
        jetton,
        customRef,
        amount,
        loanAddress,
        forwardAmount = toNano('0.4'),
        decimals
    }: {
        type: PayloadType,
        jetton: Address,
        loanAddress: Address,
        amount: bigint,
        customRef?: Cell,
        forwardAmount?: bigint
        decimals: number
    }) {
    //brain f@ck had successfully started!
    //first, get user's(my) jetton address
    const myJettonAddress = await provider.open(JettonMaster.fromAddress(jetton!)).getGetWalletAddress(getProviderSender().address!);
    console.log(`My jetton: ${myJettonAddress.toString()}`)
    //get this wallet's contract
    const myJettonWallet = provider.open(JettonChildWallet.fromAddress(myJettonAddress));
    //get the data of this wallet, to check the balance
    const walletData = await myJettonWallet.getGetWalletData();
    // console.log(`My jetton's wallet data: ${JSON.stringify(walletData)}`);
    console.log(`My jettons wallet balance: ${walletData.balance} jetton`);
    //check if user has enough balance of this token
    if (walletData.balance < amount) {
        throw new NotEnoughBalanceError(`Not enough balance in jetton: need ${fromNanoDigits(amount, decimals)}, you have ${fromNanoDigits(walletData.balance, decimals)}`, walletData.balance, amount);
    }
    //checks passed - now initialize the payload
    const payload = beginCell().storeUint(type, 8);
    if (customRef) payload.storeRef(customRef);
    //send jetton to smart contract address, (our) loan contract would receive notification using forward_payload and TokenRecievedMessage, sent by jetton smart contract
    await myJettonWallet.send(getProviderSender(), {value: forwardAmount + toNano('0.1'), bounce: false}, {
        $$type: 'TokenTransfer',
        amount,
        queryId: 0n,
        destination: loanAddress,
        custom_payload: null,
        response_destination: getProviderSender().address!,
        forward_ton_amount: forwardAmount,
        forward_payload: payload.endCell()
    });

}

export async function giveLoan(loanAddress: Address) {
    const loan = getLoanContract(loanAddress);
    const loanCurrent = await loan.getData();
    const {wantAmount: loanAmount, jetton} = loanCurrent.activeLoan!;

    //Let's check, if this jetton is even accepted by the smart contract (in other words, it is initialized correctly); otherwise, the contract would ping pong our sent jettons back.
    await checkJetton(loanAddress, loanCurrent.acceptJettons, jetton);
    if (loanCurrent.started) throw new LoanInvalidStateError("Loan already started");
    if (loanCurrent.stopped) throw new LoanInvalidStateError("Loan stopped");
    //if jetton is null - just send TON's
    if (loanCurrent.activeLoan.jetton === null || loanCurrent.activeLoan.jetton.equals(DefAddress)) {
        return loan.send(getProviderSender(), {value: toNano('0.02') + loanAmount}, {
            $$type: 'StartMsg'
        });
    } else {
        const {decimals} = await getJettonData(jetton);
        //send jettons with custom payload START_JETTON
        await sendJettonWithPayload({
            type: PayloadType.START_JETTON,
            jetton: jetton!,
            amount: loanAmount,
            loanAddress,
            forwardAmount: toNano('0.4'),
            decimals
        })
        // const myJettonAddress = await provider.open(JettonMaster.fromAddress(jetton!)).getGetWalletAddress(getProviderSender().address!);
        // //get this wallet's contract
        // const myJettonWallet = provider.open(JettonChildWallet.fromAddress(myJettonAddress));
        // //get the data of this wallet, to check the balance
        // const walletData = await myJettonWallet.getGetWalletData();
        // //check if user has enough balance of this token
        // if(walletData.balance < loanAmount) {
        //     throw new NotEnoughBalanceError("Not enough balance in jetton", walletData.balance, loanAmount);
        // }
        // //checks passed - now initialize the payload. 2 - key for "starting the loan" (hardcoded into the smart contract)
        // const payload = beginCell().storeUint(2, 8).endCell();
        // //send jetton to smart contract address, it would receive notification using forward_payload and TokenRecievedMessage, sent by jetton smart contract
        // await myJettonWallet.send(getProviderSender(), {value: toNano('0.5'), bounce: false}, {
        //     $$type: 'TokenTransfer',
        //     amount: loanAmount,
        //     queryId: 0n,
        //     destination: loanAddress,
        //     custom_payload: null,
        //     response_destination: getProviderSender().address!,
        //     forward_ton_amount: toNano('0.4'),
        //     forward_payload: payload
        // });
    }

}

export async function cancelOffer(loanAddress: Address, offerId: number, isJetton: boolean) {
    const sender = getProviderSender();
    await getLoanContract(loanAddress).send(sender, {value: isJetton ? toNano('0.12') : toNano('0.03')}, {
        $$type: "CancelOffer",
        offerIndex: BigInt(offerId)
    });
}

export async function tryGetData(loanAddress: Address): Promise<LoanData> {
    const loan = getLoanContract(loanAddress);
    return loan.getData();
}

export async function tryGetLoanReady(loanAddress: Address) {
    const loan = getLoanContract(loanAddress);
    return loan.getRunnable();

}

export async function createLoan(nftAddress: Address, dataToTransferLoan: Loan) {
    const sender = getProviderSender();
    await sender.send({
        to: nftAddress,
        sendMode: SendMode.PAY_GAS_SEPARATELY,
        body: transferMessage({
            newOwner: getMaster().address,
            forwardAmount: toNano('0.075'),
            forwardPayloadFun: storeLoan(dataToTransferLoan),
            responseTo: sender.address,
            queryId: 0
        }),
        value: toNano('0.15'),
    });
}

export async function waitForCreateLoanDeploy(nftAddress: Address) {
    const sender = getProviderSender();
    let nftAddressNow: Address;
    for (let i = 0; i < 50; i++) {
        const nftOwner = await TonApi.getNFTById(nftAddress.toString());
        nftAddressNow = Address.parse(nftOwner.owner.address);
        if (nftAddressNow.equals(getMaster().address) || nftAddressNow.equals(sender.address!)) {
        } else break;
        await new Promise(e => setTimeout(e, 5000));
    }
    await new Promise(e => setTimeout(e, 10_000));
    return getLoanContract(nftAddressNow!);

}

export async function withdrawNftNotRepayed(loanAddress: Address) {
    const loan = getLoanContract(loanAddress);
    const loanData = await loan.getData();
    if (loanData.activeLoan.jetton === null) {
        await loan.send(getProviderSender(), {value: toNano('0.02') + loanData.merchantInterest}, {
            $$type: 'WithdrawNFTNotRepayed'
        });
    } else {
        const {decimals} = await getJettonData(loanData.activeLoan.jetton);
        await sendJettonWithPayload({
            type: PayloadType.REDEEM_NOT_REPAYED,
            jetton: loanData.activeLoan.jetton!,
            amount: loanData.merchantInterest,
            forwardAmount: toNano('0.12'),
            loanAddress,
            customRef: beginCell().storeUint(0, 8).endCell(),
            decimals
        });
    }
}

export async function tryAcceptOffer(loanAddress: Address, offerId: number, isJetton: boolean) {
    const sender = getProviderSender();
    await getLoanContract(loanAddress).send(sender, {value: isJetton ? toNano('0.12') : toNano('0.02')}, {
        $$type: "StartOfferIndex",
        offerIndex: BigInt(offerId)
    });
}


export async function withdrawlProfit(jetton: Address | null, amount: bigint) {
    const loan = getMaster();
    return loan.send(getProviderSender(), {value: jetton === null ? toNano('0.02') : toNano('0.1')}, {
        $$type: 'WithdrawlToken',
        amount,
        myJetton: jetton
    });
}