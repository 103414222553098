import LoadingSpinner from "../../utils/LoadingSpinner.tsx";
import {useCallback, useRef, useState} from "preact/hooks";
import {showTonConnectLoginPage, useUserAddress} from "../../../tonConnect.ts";
import {Address, toNano} from "@ton/core";
import OneOffer from "./OneOffer.tsx";
import {cancelOffer, sendOffer, tryAcceptOffer} from "../../../contracts/main.ts";
import {showApproveDialog, showApproveDialogLoading} from "../../ApproveTransactionDialog/ApproveTransactionDialog.tsx";
import DataBlock from "../../utils/DataBlock.tsx";
import OffersHead from "./OffersHead.tsx";
import LoanOfferFilters from "./Filters/LoanOfferFilters.tsx";
import Styles from "./LoanOffers.module.scss";
import Styles2 from "./OffersAll.module.scss";
import {FiltersOfferType} from "./Filters/FiltersOfferType.tsx";
import {IJetton} from "../../../config.ts";
import {OfferLoan} from "../../../contracts/Contracts/tact_LoanContract.ts";

export default function LoanOffers({offers, loanAddress, updateOffers, loanOwner, jettons}: {
    offers?: { [k: number]: OfferLoan },
    loanAddress: string,
    updateOffers: () => unknown,
    loanOwner: Address | null,
    started: boolean,
    jettons: IJetton[]
}) {
    const userAddress = useUserAddress();
    const userAddressAddress = userAddress != null ? Address.parse(userAddress) : null;
    const isOwner = userAddress !== null && !!loanOwner?.equals(Address.parse(userAddress));
    const [makingOffer, setMakingOffer] = useState(false);
    if (!offers) return (
        <div style={{textAlign: "center"}}>
            <LoadingSpinner size={10}/>
        </div>
    );
    const makeOfferBtnClick = useCallback(() => {
        if (userAddress === null) return showTonConnectLoginPage();
        setMakingOffer(true)
    }, [userAddress]);
    const [filters, setFilters] = useState<FiltersOfferType>();
    const offersRef = useRef<{ [_: number]: OfferLoan }>();
    offersRef.current = offers;
    return (
        <DataBlock class={Styles.centered}>
            <LoanOfferFilters onFiltersChange={setFilters}/>
            {Object.keys(offers).length === 0 && <NoOffersFound/>}
            {Object.keys(offers).length !== 0 && <OffersHead/>}
            <div className={Styles.offers}>
                {Object.entries(offers).filter(filterFunc(filters)).map(([id, offer]) => (
                    <OneOffer
                        key={id}
                        jetton={jettons.find(e => {
                            if (offer.offer.jetton === null) return e.address === null;
                            return e.address !== null && offer.offer.jetton.equals(e.address);
                        })!}
                        offer={offer}
                        cancelOffer={() => cancelOfferIT(+id, offer.offer.jetton !== null)}
                        cancelable={userAddress != null && offer.owner.equals(Address.parse(userAddress!))}
                        acceptable={isOwner}
                        acceptOffer={() => acceptOffer(+id, offer.offer.jetton !== null)}
                    />
                ))}
            </div>
        </DataBlock>
    );


    async function cancelOfferIT(offerId: number, isJetton: boolean) {
        try {
            await showApproveDialog(async () => {
                await cancelOffer(Address.parse(loanAddress), offerId, isJetton);
            });
            await showApproveDialogLoading(async () => {
                for (let i = 0; i < 10; i++) {
                    await new Promise(e => setTimeout(e, 10_000));
                    updateOffers();

                    if (Object.keys(offersRef.current!).includes(offerId.toString())) return;
                }
                throw new Error("Offer not removed (for some reason)");
            });
        } catch (e) {
            console.error(e);
        }
    }

    async function acceptOffer(offerId: number, isJetton: boolean) {
        await showApproveDialog(() => tryAcceptOffer(Address.parse(loanAddress), offerId, isJetton));
        await showApproveDialogLoading(async () => {
            for (let i = 0; i < 15; i++) {
                await new Promise(e => setTimeout(e, 10_000));
                updateOffers();
                if (!offersRef.current![offerId]) return;
            }
            throw new Error("Offer not removed (for some reason)");
        });
    }
}

function filterFunc(filters: FiltersOfferType | undefined) {
    return ([_id, offer]: [string, OfferLoan]) => {
        if ((filters?.maxARP ?? null) !== null) {
            if (offer.offer.dayInterest > toNano(filters!.maxARP!.toFixed(5)!)) return false;
        }
        if ((filters?.minLoanDays ?? null) !== null) {
            if (offer.offer.days < BigInt(filters!.minLoanDays!)) return false;
        }
        if ((filters?.minAmount ?? null) !== null) {
            if (offer.offer.wantAmount < toNano(filters!.minAmount!.toFixed(5)!))
                return false;
        }
        return true;
    }
}

function NoOffersFound() {
    return (
        <i class={Styles.noOffers}>
            No offers found.
        </i>
    )
}