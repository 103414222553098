import {useUserAddress} from "../tonConnect.ts";
import {useEffect, useState} from "preact/hooks";
import {Address, fromNano, toNano} from "@ton/core";
import {getMasterData, withdrawlProfit} from "../contracts/main.ts";
import TonApi, {Currency} from "../api/tonapi/TonApi.ts";
import {DefaultJettons} from "../config.ts";
import OneJettonToWithdraw, {IJettonWithBalance} from "../components/OneJettonToWithdraw/OneJettonToWithdraw.tsx";
import DataBlock from "../components/utils/DataBlock.tsx";
import TonInput from "../components/NumberInputs/TonInput.tsx";
import Styles from "./Admin.module.scss";
import StylesInput from "../components/NumberInputs/InputStyles.module.scss";
import Button from "../components/buttons/Button.tsx";
import {
    showApproveDialog,
    showApproveDialogLoading
} from "../components/ApproveTransactionDialog/ApproveTransactionDialog.tsx";
import {createRef} from "preact/compat";
import {fromNanoDigits, toNanoDigits} from "../contracts/utils.ts";

export default function AdminPage() {
    const [masterOwner, setOwner] = useState<Address>();
    const [balances, setBalances] = useState<IJettonWithBalance[]>([]);
    const [selectedIndex, setSelectedIndex] = useState<number>(0);
    const [tonAmountSelected, setTonAmountSelected] = useState<number>(0);
    const userAddress = useUserAddress();
    const balancesRef = createRef<IJettonWithBalance[]>();
    balancesRef.current = balances;
    useEffect(() => void parseData(), []);
    if (!userAddress) return <>Not found</>;
    if (!masterOwner) return <>Loading</>;
    if (!masterOwner.equals(Address.parse(userAddress)))
        return <>Not allowed</>;
    const jettonSelected = balances![selectedIndex]!!;
    return (
        <DataBlock class={Styles.block}>
            <h2>Balances of master</h2>
            {
                balances?.map((e, index) =>
                    <OneJettonToWithdraw
                        jetton={e}
                        key={index}
                        isSelected={selectedIndex === index}
                        onClick={() => setSelectedIndex(index)}
                    />)
            }
            <div style={{display: 'flex'}}>
                <TonInput
                    placeholder="Select amount"
                    class={Styles.tonInput}
                    onChange={v => setTonAmountSelected(v ?? 0)} default={tonAmountSelected} min={0.01}
                    max={+jettonSelected.balance}
                    prefix={<img src={jettonSelected.icon} class={StylesInput.prefix} width={30} height={30}/>}
                />
            </div>
            <Button class={Styles.withdrawlBtn} onClick={withdrawlIT}>Withdraw</Button>
        </DataBlock>
    );

    async function parseData() {
        console.log("parseData");
        const {owner, address} = await getMasterData();
        const [balances, tonBalance, tonPriceUSD] = await
            Promise.all([
                TonApi.getTokenBalanceOf(address.toString(), [Currency.TON, Currency.RUB, Currency.USD]),
                TonApi.getBalanceOf(address.toString()),
                TonApi.getTonPrice([Currency.USD])
            ]);
        console.log("balances", balances);
        setOwner(owner);
        setBalances(
            [
                {
                    ...DefaultJettons[0],
                    balance: (+fromNano(tonBalance)).toFixed(3),
                    amountUSD: tonPriceUSD * +fromNano(tonBalance)
                },
                ...balances.balances.map(e => ({
                    balance: (+fromNanoDigits(BigInt(e.balance), e.jetton.decimals)).toFixed(3),
                    icon: e.jetton.image,
                    address: Address.parse(e.wallet_address.address),
                    title: e.jetton.symbol,
                    amountUSD: e.price.prices.USD,
                    decimals: e.jetton.decimals
                }))
            ]
        );

    }

    async function withdrawlIT() {
        console.log("withdrawlIT");
        const amountReal = toNano(tonAmountSelected.toFixed(2));
        await showApproveDialog(async () => {
            await withdrawlProfit(jettonSelected.address, amountReal);
        });
        await showApproveDialogLoading(async () => {
            for (let i = 0; i < 20; i++) {
                await new Promise(e => setTimeout(e, 10000));
                await parseData();
                if (balances[i].balance !== balancesRef.current![i].balance) {
                    return
                }
            }
            throw new Error("Timed out");

        });
    }
}