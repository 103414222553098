import Styles from "./LoaOfferFilters.module.scss";
import InputNumber from "rc-input-number";
import {FiltersOfferType} from "./FiltersOfferType.tsx";
import {useEffect, useState} from "preact/hooks";
import ARPInput from "../../../NumberInputs/ARPInput.tsx";
import DurationInput from "../../../NumberInputs/DurationInput.tsx";
import TonInput from "../../../NumberInputs/TonInput.tsx";

export default function LoanOfferFilters({onFiltersChange}: { onFiltersChange: (filtes: FiltersOfferType) => void }) {
    const [filters, setFilters] = useState<FiltersOfferType>({
        maxARP: null,
        minLoanDays: null,
        minAmount: null
    });
    useEffect(() => {
        onFiltersChange(filters);
    }, [filters, onFiltersChange]);
    return (
        <div class={Styles.filters}>
            <h4>Offers on this asset</h4>
            <div>
                <ARPInput onChange={v => setFilters({...filters, maxARP: (v ?? 0) / 100})} placeholder="MAX ARP"/>
            </div>
            <div>
                <DurationInput onChange={v => setFilters({...filters, minLoanDays: v})}
                               placeholder="MIN duration (days)"/>
            </div>
            <div>
                <TonInput onChange={v => setFilters({...filters, minAmount: v})} placeholder="MIN amount"/>
            </div>

        </div>
    )
}