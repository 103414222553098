import Styles from "./DataBlockInformation.module.scss";
import DataBlock from "../../utils/DataBlock.tsx";
import {fromNano} from "@ton/core";
import {LoanConditions} from "../../../routes/client/Loan.tsx";
import {getTonViewerLink} from "../../../config.ts";
import {fromNanoDigits} from "../../../contracts/utils.ts";

export default function DataBlockInformation(
    {
        title,
        collection,
        collectionHref,
        loanData: {wantAmount, days, dayInterest, jetton} = {wantAmount: 0n, days: 0n, dayInterest: 0n, jetton: {title: 'TON', address: null, icon: '', decimals: 9}},
        started
    }: {
        title: string,
        collection: string,
        collectionHref: string,
        loanData?: LoanConditions,
        started: boolean
    }) {
    const jettonEl = jetton.address ?
        <a href={getTonViewerLink(jetton.address)} target="_blank">{jetton.title}</a> : <>{jetton.title}</>
    return (
        <DataBlock class={Styles.informationBlock}>
            <h4 class={Styles.title}>NFT information</h4>
            <h2 class={Styles.nftTitle}>{title}</h2>
            <a class={Styles.description} href={collectionHref} target="_blank">{collection}</a>
            {wantAmount !== 0n && <>
                <h4 className={Styles.title}>{!started ? 'Preferred loan conditions' : 'Loan conditions'}</h4>
                <p>
                    <span> Amount: <b>{fromNanoDigits(wantAmount, jetton?.decimals??9)} {jettonEl}</b> </span>

                    <br/>
                    <span> Days: <b>{days}</b> </span>
                    <br/>

                    <span> Day interest: <b>{Math.round(Number(100_00n * dayInterest / wantAmount)) / 100}%</b> </span>
                </p>
            </>
            }
        </DataBlock>
    )
}