import Styles from './OneNFT.module.scss'
import {toUserFriendlyAddress} from "@tonconnect/ui";
import OneNftActionBtns from "./OneNftActionBtn.tsx";
import {nftLink} from "../../config.ts";
import FormatAddress from "../utils/FormatAddress.tsx";
import DataBlock from "../utils/DataBlock.tsx";
export default function OneNFT({address: addr, preview, name}: { address: string, preview: string, name: string }) {
    const address = toUserFriendlyAddress(addr);
    return (
        <DataBlock class={Styles.oneNFT}>
            <a href={nftLink(addr)} target="_blank" rel="noreferrer">
                <img src={preview} alt={name} width={198} height={198}/>
            </a>
            <h3>{name}</h3>
            <p><FormatAddress address={address} href={nftLink(address)} /></p>
            <OneNftActionBtns address={address} />
        </DataBlock>
    )
}


