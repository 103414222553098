import {getGetgemsLink, LoanNFT} from "../../config.ts";
import Styles from "./JewelerNFT.module.scss";
import Button from "../buttons/Button.tsx";
import {NftData} from "../../routes/jeweler";
import {fromNano} from "@ton/core";
import DataBlock from "../utils/DataBlock.tsx";
import NftMetadata, {parseDataJetton} from "./NftMetadata.tsx";
import {fromNanoDigits} from "../../contracts/utils.ts";

export default function JewelerNFT({nft, onLend, onCustomize}: {
    nft: NftData,
    onLend: (nft: LoanNFT) => void,
    onCustomize: (nft: LoanNFT) => void
}) {
    return (
        <DataBlock class={Styles.jewelerNFT+(nft.stopped?' '+Styles.stopped:'')}>
            <a class={Styles.title + ' ' + Styles.padding}
               href={getGetgemsLink(nft.nft_item.address)}>{nft.nft_item.metadata?.name}</a>
            <a class={Styles.collection + ' ' + Styles.padding} target="_blank"
               href={getGetgemsLink(nft.nft_item.collection.address, true)}>{nft.nft_item.collection.name ?? "No collection"}</a>
            <NftMetadata nft={nft}/>
            <div class={Styles.buttons + ' '}>
                <Button onClick={() => onLend(nftToLoan(nft))}>Lend</Button>
                <Button onClick={() => onCustomize(nftToLoan(nft))}>Customize</Button>
            </div>
        </DataBlock>
    );
}


function nftToLoan(nft: NftData): LoanNFT {
    const jetton = parseDataJetton(nft.activeLoan_jetton);
    return {
        title: nft.nft_item.metadata?.name ?? "No name",
        want_period_days: +nft.activeLoan_days,
        want_interest: +nft.activeLoan_dayInterest,
        id: nft._id,
        image: nft.nft_item.previews[2].url,
        description: nft.nft_item.metadata?.description ?? "No description",
        link: `/loan/${nft.loan}`,
        want_amount_ton: +fromNanoDigits(BigInt(nft.activeLoan_wantAmount), jetton.decimals),
    }
}