import {LoansAPIResponse} from "./interfaces.ts";


class LoansApi {
    constructor(private readonly gateway: string, private readonly token: string) {
    }

    async get(params: {
        limit: number,
        skip: number,
        owner?: string,
        nft?: string
    }) {
        return this.method<LoansAPIResponse>('GET', '/loans', params as any);
    }

    private async method<T>(method: 'GET' | 'POST', uri: string, params: { [l: string]: string }): Promise<T> {
        const res = await fetch(`${this.gateway}${uri}?${new URLSearchParams(params)}`, {
            method,
            headers: {
                authorization: `Bearer ${this.token}`
            }
        });
        const json = await res.json();


        return json as T;
    }
}


export default function useLoansApi() {
    return new LoansApi(import.meta.env.VITE_API_GATEWAY, import.meta.env.VITE_API_TOKEN);
}

