import {TonApiOneNFT} from "../../api/tonapi/interfaces.ts";
import Styles from "./NftItemForLoan.module.scss";
import {toUserFriendlyAddress} from "@tonconnect/ui";
import {useEffect, useState} from "preact/hooks";
import {Address, toNano} from "@ton/core";
import {useNavigate} from "react-router-dom";
import {useUserAddress} from "../../tonConnect.ts";
import {createLoan, getJettonData, tryGetLoanReady, waitForCreateLoanDeploy} from "../../contracts/main.ts";
import {showApproveDialog, showApproveDialogLoading} from "../ApproveTransactionDialog/ApproveTransactionDialog.tsx";
import Content, {Data} from "../utils/Content.module.tsx";
import TonConnectBtn from "../buttons/TonConnectBtn.tsx";
import DataBlock from "../utils/DataBlock.tsx";
import GetGemsIcon from "../../static/getgems.png";
import {DefaultJettons, getGetgemsLink, nftLink} from "../../config.ts";
import ImageIcon from "../utils/ImageIcon.tsx";
import DataBlockImage from "../NftDataLoan/DataBlocks/DataBlockImage.tsx";
import DataBlockInformation from "../NftDataLoan/DataBlocks/DataBlockInformation.tsx";
import MakeOfferData from "../NftDataLoan/MakeOffer/MakeOfferData.tsx";
import {toNanoDigits} from "../../contracts/utils.ts";

export default function NftItemForLoan({item, onRefresh}: { item: TonApiOneNFT, onRefresh: () => unknown }) {
    const userAddress = useUserAddress();
    const [submiting, setSubmiting] = useState(false);
    const navigate = useNavigate();
    if (userAddress != toUserFriendlyAddress(item.owner.address)) {
        useEffect(() => {
            getData();
        }, [])
        return (
            <Content>
                <Data>
                    <DataBlock style={{flex: 1, padding: "20px"}}>
                        <h4>Not eligible</h4>
                        <br/>
                        <p>You're not an owner of this NFT, thus you're not able to pawn it. Try connecting another
                            wallet.</p>
                    </DataBlock>
                </Data>
            </Content>
        )
            ;
    }
    if (!item) return <></>
    return (
        <Content>
            <Data>
                <DataBlockImage src={item?.previews?.[2]?.url ?? ""} alt={item?.metadata?.name ?? ''}
                                onRefresh={onRefresh} nftAddress={item.address} loanAddress={item.address}/>
                <DataBlockInformation title={item.metadata?.name ?? 'Unknown'} started={false}
                                      collection={item.collection?.name ?? "No collection"}
                                      collectionHref={nftLink(item.address)}/>
                <DataBlock>
                    <MakeOfferData
                        jettons={DefaultJettons}
                        onSubmit={data => submit({
                            ton: data.wantAmount,
                            interest: data.arp,
                            days: data.loanDurationDays,
                            jetton: data.jetton.address
                        })}
                        onAcceptDefault={() => {
                        }}
                        canAcceptDefault={false}
                        title="Want loan conditions"
                        submitTitle="Create loan contract"
                    />
                </DataBlock>
            </Data>

            <DataBlock style={{padding: "20px"}}>
                <h4>How does loan process works?</h4>
                <p style={{marginLeft: "18px", padding: "10px"}}>
                    <ol>
                        <li>You create a loan (smart contract) with preferred conditions</li>
                        <li>You select jettons, which you find preferable, in which you would receive offers</li>
                        <li style={{paddingLeft: "20px"}}>
                            <br/>
                            <ul>
                                <li>If someone finds you conditions suitable, he can start the loan.</li>
                                <li>Other users gives you offers (with their conditions), which you can accept (or
                                    not).
                                </li>
                                <li>If you change your mind and loan is not started yet — you can cancel it anytime</li>
                            </ul>
                        </li>
                        <li>After loan is started, your NFT is blocked in smart contract</li>
                        <li style={{paddingLeft: "20px"}}>
                            <br/>
                            <ul>
                                <li>You can withdraw it anytime before loan ends: just send <b>loan
                                    mount</b> + <b>ARP</b> * <b>days passed</b> + <b>little fee</b> (our app calculates
                                    this for you)
                                </li>
                                <li>If <b>Loan duration</b> time passed (after loan start), you're not able to withdraw
                                    your NFT anymore.
                                </li>
                            </ul>
                        </li>
                    </ol>
                    <br/>
                    All actions are <b>safe</b>, because they are covered by magic of our <b>SMART CONTRACT</b> in TON
                    blockchain.
                </p>
            </DataBlock>
        </Content>
    )

    async function submit({ton, interest, days, jetton = null}: {
        ton: number,
        interest: number,
        days: number,
        jetton: Address | null
    }) {
        if (submiting) return;
        setSubmiting(true);
        console.log({ton, interest, days, jetton});
        try {
            await showApproveDialog(async () => {
                let decimals: number = 9;
                if(jetton){
                    const jettonData = await getJettonData(jetton);
                    decimals = jettonData.decimals;
                }
                console.log("Creating loan with", {ton, interest, days, jetton});
                await createLoan(
                        Address.parse(item.address),
                        {
                            days: BigInt(days),
                            dayInterest: toNanoDigits((interest * ton).toFixed(decimals), decimals),
                            wantAmount: toNanoDigits((ton).toFixed(decimals), decimals),
                            jetton,
                            $$type: 'Loan'
                        }
                    )
                }
            );
            const contract = await showApproveDialogLoading(
                waitForCreateLoanDeploy(Address.parse(item.address))
            );
            // const data = await contract.getContractData();
            navigate(`/loan/${contract.address.toString({urlSafe: true})}`);
//             console.log(`
// NFT: ${data.nft.toString({urlSafe: true})}
// Owner: ${data.owner.toString({urlSafe: true})}
// Amount: ${data.amount}
// Loan duration: ${data.loanDuration}
// APR: ${data.aprAmount}
// Active: ${data.active}
// OfferAccepted: ${data.offerAccept}
// offers: ${data.offers.keys()}
// `);

        } finally {
            setSubmiting(false);
        }
    }

    async function getData() {
        try {
            console.log(item.owner);
            const loan = await tryGetLoanReady(Address.parse(item.owner.address))
            navigate(`/loan/${toUserFriendlyAddress(item.owner.address)}`);
        } catch (e) {
            console.error(e);
        }
        // const master = await getDeployedNftLoan(Address.parse(item.owner.address), Address.parse(item.address));
        // if (master !== null) {
        //     navigate(`/client/loan/${master.address.toString({urlSafe: true})}`);
        // }
    }
}