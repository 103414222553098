import Styles from "./MakeOfferData.module.scss"
import {useState} from "preact/hooks";
import Bubbled from "./Bubbled.tsx";
import Button from "../../buttons/Button.tsx";
import Back from "../../../static/back.svg"
import FadeOutblock from "./FadeOutblock.tsx";
import InputNumber from 'rc-input-number';
import TonInput from "../../NumberInputs/TonInput.tsx";
import DurationInput from "../../NumberInputs/DurationInput.tsx";
import ARPInput from "../../NumberInputs/ARPInput.tsx";
import JettonInput from "../../NumberInputs/JettonInput.tsx";
import {DefaultJettons, IJetton} from "../../../config.ts";

export default function MakeOfferData(
    {
        onSubmit,
        onAcceptDefault,
        canAcceptDefault,
        default: def,
        title = 'Make offer',
        submitTitle = 'Create offer',
        jettons
    }: {
        onSubmit: (data: InputNewOfferData) => unknown,
        onAcceptDefault?: () => unknown,
        canAcceptDefault?: boolean,
        default?: InputNewOfferData,
        title?: string,
        submitTitle?: string,
        jettons: IJetton[]
    }) {
    canAcceptDefault ??= true;
    const [opened, setOpened] = useState(!canAcceptDefault);

    if (!opened) return <NotOpened onClick={() => setOpened(true)} onAcceptDefault={onAcceptDefault ?? (() => {
    })}/>
    return <Opened
        onSubmit={onSubmit} setOpened={setOpened}
        default={def ?? {arp: 0.02, loanDurationDays: 10, wantAmount: 25, jetton: {title: 'TON', address: null, icon: 'unknown', decimals: 9}}}
        title={title}
        showBackButton={canAcceptDefault}
        submitTitle={submitTitle}
        jettons={jettons}
    />
}
export type InputNewOfferData = {
    wantAmount: number;
    loanDurationDays: number;
    arp: number;
    jetton: IJetton;
}

function Opened({setOpened, showBackButton, default: def, onSubmit, title, submitTitle, jettons}: {
    setOpened: (now: boolean) => unknown,
    onSubmit: (data: InputNewOfferData) => unknown,
    default: InputNewOfferData,
    title: string,
    showBackButton: boolean,
    submitTitle: string,
    jettons: IJetton[]
}) {
    const [openedIndex, setOpenedIndex] = useState(-1);
    const [data, setData] = useState<InputNewOfferData>(def);
    return (
        <div className={Styles.makeOfferContent}>
            <h4>{title}</h4>
            <p>Set your terms</p>
            <br/>
            <div>
                <FadeOutblock
                    opened={openedIndex === -2}
                    onOpen={e => setOpenedIndex(e ? -2 : -1)}
                    title={<>Jetton: <b>{data.jetton.title}</b></>}>
                    <JettonInput
                        onChange={e => setData({...data, jetton: e})}
                        jettons={jettons}
                    />
                </FadeOutblock>

                <FadeOutblock
                    opened={openedIndex === 0}
                    onOpen={e => setOpenedIndex(e ? 0 : -1)}
                    title={<>Amount: <b>{data.wantAmount} {data.jetton.title}</b></>}>
                    <TonInput
                        onChange={e => setData({...data, wantAmount: +(e ?? 1)})}
                        placeholder="TON amount"
                        default={def.wantAmount}
                        onTabPress={() => setOpenedIndex(1)}
                        class={Styles.input}
                        visible={openedIndex === 0}
                    />
                </FadeOutblock>
                <FadeOutblock title={<>Loan duration: <b>{data.loanDurationDays} days</b></>} opened={openedIndex === 1}
                              onOpen={e => setOpenedIndex(e ? 1 : -1)}>
                    <DurationInput
                        default={def.loanDurationDays}
                        onChange={e => void setData({...data, loanDurationDays: +(e ?? 0)})}
                        placeholder="Days"
                        onTabPress={() => setOpenedIndex(2)}
                        class={Styles.input}
                        visible={openedIndex === 1}
                    />
                </FadeOutblock>
                <FadeOutblock title={<>Daily ARP: <b>{(data.arp * 100).toFixed(1)}%</b> |
                    Repayment: <b>{(data.arp * data.wantAmount * data.loanDurationDays).toFixed(2)} {data.jetton.title}</b></>}
                              opened={openedIndex === 2}
                              onOpen={e => setOpenedIndex(e ? 2 : -1)}>
                    <ARPInput
                        onChange={e => void setData({...data, arp: +(e ?? 1) / 100})} placeholder="ARP"
                        class={Styles.input} def={def.arp * 100}
                        visible={openedIndex === 2}
                    />
                </FadeOutblock>
            </div>

            <div className={Styles.bottomBtnContainer}>
                {showBackButton && <Button onClick={() => setOpened(false)}><img src={Back}/></Button>}

                <Button onClick={() => onSubmit(data)}>{submitTitle}</Button>
            </div>
        </div>
    )
}

function NotOpened({onClick, onAcceptDefault}: { onClick: () => unknown, onAcceptDefault: () => unknown }) {
    return <>
        <div className={Styles.makeOfferContent}>
            <h4>Make offer</h4>
            <p>You can specify</p>
            <Bubbled>Any jetton</Bubbled>
            <Bubbled>Any amount</Bubbled>
            <Bubbled>Any duration</Bubbled>
            <Bubbled>Any ARP</Bubbled>
            <div className={Styles.bottomBtnContainer}>
                <Button onClick={onClick}>Make offer</Button>
            </div>
            <div className={Styles.bottomBtnContainer}>
                <Button onClick={onAcceptDefault}>Accept preferred conditions</Button>
            </div>
        </div>
    </>

}