import {useEffect, useState} from "preact/hooks";
import {ConnectedWallet, TonConnectUI, toUserFriendlyAddress} from "@tonconnect/ui";

const onStatusChangeListners = new Set<(wallet: ConnectedWallet) => void>();
const onErrorListners = new Set<(error: Error) => void>();
const tonConnectUI = new TonConnectUI({
    manifestUrl: 'https://gist.github.com/imartemy1524/0399836dc6e71fd4174f1374ab1b19fc/raw/13a977a4d3f3ad64ab71760e2658745d50092658/manifest.json',
    // buttonRootId: 'tonconnectbtn'
});

tonConnectUI.onStatusChange(s => {
    if (s) for (const listener of onStatusChangeListners) {
        listener(s);
    }
    else {
        for (const listener of onErrorListners) {
            listener(new Error('Wallet disconnected'));
        }
    }
}, e => {
    for (const listener of onErrorListners) {
        listener(e);
    }
});

export function disconnectUserWallet() {
    return tonConnectUI.disconnect();
}

export function showTonConnectLoginPage() {
    return tonConnectUI.openModal();
}

export function useUserAddress(): string | null {
    const [cn, setTonCN] = useState(tonConnectUI.account?.address ? toUserFriendlyAddress(tonConnectUI.account?.address) : null);
    useEffect(() => {
        const fn = () => {
            if(import.meta.env.MODE === 'development')
                console.log("Wallet connected: ", tonConnectUI.wallet?.account?.address ?? "Not connected!!");
            const addr = tonConnectUI.account?.address;
            if (addr) setTonCN(toUserFriendlyAddress(addr));
            else setTonCN(null);
        }
        onStatusChangeListners.add(fn);
        onErrorListners.add(fn);
        return () => {
            onStatusChangeListners.delete(fn);
            onErrorListners.delete(fn);
        }
    }, []);
    return cn;
}

Object.defineProperties(window, {
    tonConnect: {
        get(){return tonConnectUI.connector;},
    }
})
