import {IJetton} from "../../config.ts";
import {useRef} from "preact/hooks";

import Styles from "./JettonInput.module.scss"
export default function JettonInput({jettons, onChange}: {
    jettons: IJetton[],
    onChange: (address: IJetton) => unknown
}) {
    const select = useRef<HTMLSelectElement>(null);
    return (
        <select ref={select} onChange={()=>onChange(jettons[select.current!.selectedIndex]!)} class={Styles.jettonSelect}>
            {
                jettons.map(jetton => (
                    <option value={jetton.address?.toString() ?? ''}><img src={jetton.icon} width={"15px"} height={"15px"}
                                                              alt={jetton.title}/> {jetton.title}</option>
                ))
            }
        </select>
    )
}