export function toNanoDigits(src: string, digits: number): bigint {
    // Check sign
    let neg = false;
    while (src.startsWith('-')) {
        neg = !neg;
        src = src.slice(1);
    }

    // Split string
    if (src === '.') {
        throw Error('Invalid number');
    }
    let parts = src.split('.');
    if (parts.length > 2) {
        throw Error('Invalid number');
    }

    // Prepare parts
    let whole = parts[0];
    let frac = parts[1];
    if (!whole) {
        whole = '0';
    }
    if (!frac) {
        frac = '0';
    }
    if (frac.length > digits) {
        throw Error('Invalid number');
    }
    while (frac.length < digits) {
        frac += '0';
    }

    // Convert
    let r = BigInt(whole) * (10n ** BigInt(digits)) + BigInt(frac);
    if (neg) {
        r = -r;
    }
    return r;

}

export function fromNanoDigits(v: bigint, digits: number): string {
    let neg = false;
    if (v < 0) {
        neg = true;
        v = -v;
    }

    // Convert fraction
    let frac = v % ((10n ** BigInt(digits)));
    let facStr = frac.toString();
    while (facStr.length < digits) {
        facStr = '0' + facStr;
    }
    facStr = facStr.match(/^([0-9]*[1-9]|0)(0*)/)![1];

    // Convert whole
    let whole = v / ((10n ** BigInt(digits)));
    let wholeStr = whole.toString();

    // Value
    let value = `${wholeStr}${facStr === '0' ? '' : `.${facStr}`}`;
    if (neg) {
        value = '-' + value;
    }

    return value;
}