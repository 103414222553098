import {
    ABIGetter,
    ABIReceiver, ABIType,
    Address, beginCell, Builder,
    Cell,
    Contract,
    ContractABI, contractAddress,
    ContractProvider,
    Sender,
    Slice,
    TupleBuilder, TupleReader
} from "@ton/core";
import {storeTokenTransfer, TokenTransfer} from "./tact_LoanContract.ts";


export type TokenTransferInternal = {
    $$type: 'TokenTransferInternal';
    queryId: bigint;
    amount: bigint;
    from: Address;
    response_destination: Address;
    forward_ton_amount: bigint;
    forward_payload: Cell;
}
export type TokenBurn = {
    $$type: 'TokenBurn';
    queryId: bigint;
    amount: bigint;
    owner: Address;
    response_destination: Address;
}

const JettonDefaultWallet_errors: { [key: number]: { message: string } } = {
    2: {message: `Stack underflow`},
    3: {message: `Stack overflow`},
    4: {message: `Integer overflow`},
    5: {message: `Integer out of expected range`},
    6: {message: `Invalid opcode`},
    7: {message: `Type check error`},
    8: {message: `Cell overflow`},
    9: {message: `Cell underflow`},
    10: {message: `Dictionary error`},
    13: {message: `Out of gas error`},
    32: {message: `Method ID not found`},
    34: {message: `Action is invalid or not supported`},
    37: {message: `Not enough TON`},
    38: {message: `Not enough extra-currencies`},
    128: {message: `Null reference exception`},
    129: {message: `Invalid serialization prefix`},
    130: {message: `Invalid incoming message`},
    131: {message: `Constraints error`},
    132: {message: `Access denied`},
    133: {message: `Contract stopped`},
    134: {message: `Invalid argument`},
    135: {message: `Code of a contract was not found`},
    136: {message: `Invalid address`},
    137: {message: `Masterchain support is not enabled for this contract`},
    3734: {message: `Not Owner`},
    4159: {message: `Invalid value!!`},
    4429: {message: `Invalid sender`},
    6898: {message: `The total supply will be overlapping.`},
    12914: {message: `Offer is canceled`},
    17894: {message: `Invalid amount sent`},
    18668: {message: `Can't Mint Anymore`},
    22242: {message: `Invalid NFT address recieved!`},
    28249: {message: `Not an owner of the offer`},
    33502: {message: `Invalid sender: parent required`},
    33546: {message: `Invalid jetton sender`},
    34910: {message: `Not enough money`},
    38366: {message: `TON not acceptable`},
    42708: {message: `Invalid sender!`},
    43422: {message: `Invalid value - Burn`},
    43607: {message: `Invalid message type`},
    44194: {message: `Invalid sender: issuer required`},
    48926: {message: `When sending tons, jetton should be null`},
    50979: {message: `Offer not found`},
    53874: {message: `NFT not received`},
    59515: {message: `Jetton not found`},
    61809: {message: `not enough tons`},
    62497: {message: `Invalid jetton sent`},
    62972: {message: `Invalid balance`},
}

const JettonDefaultWallet_types: ABIType[] = [
    {
        "name": "StateInit",
        "header": null,
        "fields": [{"name": "code", "type": {"kind": "simple", "type": "cell", "optional": false}}, {
            "name": "data",
            "type": {"kind": "simple", "type": "cell", "optional": false}
        }]
    },
    {
        "name": "Context",
        "header": null,
        "fields": [{
            "name": "bounced",
            "type": {"kind": "simple", "type": "bool", "optional": false}
        }, {"name": "sender", "type": {"kind": "simple", "type": "address", "optional": false}}, {
            "name": "value",
            "type": {"kind": "simple", "type": "int", "optional": false, "format": 257}
        }, {"name": "raw", "type": {"kind": "simple", "type": "slice", "optional": false}}]
    },
    {
        "name": "SendParameters",
        "header": null,
        "fields": [{"name": "bounce", "type": {"kind": "simple", "type": "bool", "optional": false}}, {
            "name": "to",
            "type": {"kind": "simple", "type": "address", "optional": false}
        }, {
            "name": "value",
            "type": {"kind": "simple", "type": "int", "optional": false, "format": 257}
        }, {
            "name": "mode",
            "type": {"kind": "simple", "type": "int", "optional": false, "format": 257}
        }, {"name": "body", "type": {"kind": "simple", "type": "cell", "optional": true}}, {
            "name": "code",
            "type": {"kind": "simple", "type": "cell", "optional": true}
        }, {"name": "data", "type": {"kind": "simple", "type": "cell", "optional": true}}]
    },
    {
        "name": "Deploy",
        "header": 2490013878,
        "fields": [{"name": "queryId", "type": {"kind": "simple", "type": "uint", "optional": false, "format": 64}}]
    },
    {
        "name": "DeployOk",
        "header": 2952335191,
        "fields": [{"name": "queryId", "type": {"kind": "simple", "type": "uint", "optional": false, "format": 64}}]
    },
    {
        "name": "FactoryDeploy",
        "header": 1829761339,
        "fields": [{
            "name": "queryId",
            "type": {"kind": "simple", "type": "uint", "optional": false, "format": 64}
        }, {"name": "cashback", "type": {"kind": "simple", "type": "address", "optional": false}}]
    },
    {
        "name": "ChangeOwner",
        "header": 2174598809,
        "fields": [{
            "name": "queryId",
            "type": {"kind": "simple", "type": "uint", "optional": false, "format": 64}
        }, {"name": "newOwner", "type": {"kind": "simple", "type": "address", "optional": false}}]
    },
    {
        "name": "ChangeOwnerOk",
        "header": 846932810,
        "fields": [{
            "name": "queryId",
            "type": {"kind": "simple", "type": "uint", "optional": false, "format": 64}
        }, {"name": "newOwner", "type": {"kind": "simple", "type": "address", "optional": false}}]
    },
    {
        "name": "Loan",
        "header": null,
        "fields": [{
            "name": "wantAmount",
            "type": {"kind": "simple", "type": "uint", "optional": false, "format": 64}
        }, {
            "name": "days",
            "type": {"kind": "simple", "type": "uint", "optional": false, "format": 8}
        }, {
            "name": "dayInterest",
            "type": {"kind": "simple", "type": "uint", "optional": false, "format": 64}
        }, {"name": "jetton", "type": {"kind": "simple", "type": "address", "optional": true}}]
    },
    {
        "name": "InitLoan",
        "header": 19415667,
        "fields": [{
            "name": "loan",
            "type": {"kind": "simple", "type": "Loan", "optional": false}
        }, {"name": "nftAddress", "type": {"kind": "simple", "type": "address", "optional": false}}, {
            "name": "owner",
            "type": {"kind": "simple", "type": "address", "optional": false}
        }]
    },
    {"name": "StartMsg", "header": 135406920, "fields": []},
    {
        "name": "Offer",
        "header": 2645385394,
        "fields": [{"name": "offerLoan", "type": {"kind": "simple", "type": "Loan", "optional": false}}]
    },
    {
        "name": "OfferLoan",
        "header": null,
        "fields": [{"name": "offer", "type": {"kind": "simple", "type": "Loan", "optional": false}}, {
            "name": "owner",
            "type": {"kind": "simple", "type": "address", "optional": false}
        }]
    },
    {"name": "WithdrawNFTNotRepayed", "header": 311579522, "fields": []},
    {"name": "CancelLoan", "header": 311514246, "fields": []},
    {"name": "RedeemMessage", "header": 558310259, "fields": []},
    {
        "name": "CancelOffer",
        "header": 1012362342,
        "fields": [{"name": "offerIndex", "type": {"kind": "simple", "type": "int", "optional": false, "format": 257}}]
    },
    {
        "name": "StartOfferIndex",
        "header": 1825726764,
        "fields": [{"name": "offerIndex", "type": {"kind": "simple", "type": "int", "optional": false, "format": 257}}]
    },
    {
        "name": "ForwardNftPayload",
        "header": null,
        "fields": [{"name": "loan", "type": {"kind": "simple", "type": "Loan", "optional": false}}]
    },
    {
        "name": "SetAcceptableJettons",
        "header": 674633801,
        "fields": [{"name": "jettons", "type": {"kind": "dict", "key": "address", "value": "address"}}]
    },
    {
        "name": "OwnershipAssignedMaster",
        "header": 85167505,
        "fields": [{
            "name": "queryId",
            "type": {"kind": "simple", "type": "uint", "optional": false, "format": 64}
        }, {"name": "prevOwner", "type": {"kind": "simple", "type": "address", "optional": false}}, {
            "name": "bite",
            "type": {"kind": "simple", "type": "bool", "optional": false}
        }, {"name": "forwardPayload", "type": {"kind": "simple", "type": "ForwardNftPayload", "optional": false}}]
    },
    {
        "name": "OwnershipAssigned",
        "header": 85167505,
        "fields": [{
            "name": "queryId",
            "type": {"kind": "simple", "type": "uint", "optional": false, "format": 64}
        }, {
            "name": "prevOwner",
            "type": {"kind": "simple", "type": "address", "optional": false}
        }, {
            "name": "forwardPayload",
            "type": {"kind": "simple", "type": "slice", "optional": false, "format": "remainder"}
        }]
    },
    {
        "name": "Transfer",
        "header": 1607220500,
        "fields": [{
            "name": "queryId",
            "type": {"kind": "simple", "type": "uint", "optional": false, "format": 64}
        }, {
            "name": "newOwner",
            "type": {"kind": "simple", "type": "address", "optional": false}
        }, {
            "name": "responseDestination",
            "type": {"kind": "simple", "type": "address", "optional": false}
        }, {
            "name": "customPayload",
            "type": {"kind": "simple", "type": "bool", "optional": false}
        }, {
            "name": "forwardAmount",
            "type": {"kind": "simple", "type": "uint", "optional": false, "format": "coins"}
        }, {
            "name": "hasPayload",
            "type": {"kind": "simple", "type": "bool", "optional": false}
        }, {
            "name": "forwardPayload",
            "type": {"kind": "simple", "type": "slice", "optional": false, "format": "remainder"}
        }]
    },
    {"name": "ExcessMsg", "header": 3576854235, "fields": []},
    {
        "name": "LoanData",
        "header": null,
        "fields": [{"name": "nft", "type": {"kind": "simple", "type": "address", "optional": true}}, {
            "name": "owner",
            "type": {"kind": "simple", "type": "address", "optional": false}
        }, {"name": "stopped", "type": {"kind": "simple", "type": "bool", "optional": false}}, {
            "name": "parent",
            "type": {"kind": "simple", "type": "address", "optional": false}
        }, {"name": "started", "type": {"kind": "simple", "type": "bool", "optional": false}}, {
            "name": "loanIssuer",
            "type": {"kind": "simple", "type": "address", "optional": true}
        }, {"name": "activeLoan", "type": {"kind": "simple", "type": "Loan", "optional": true}}, {
            "name": "masterIndex",
            "type": {"kind": "simple", "type": "uint", "optional": false, "format": 64}
        }, {
            "name": "startTime",
            "type": {"kind": "simple", "type": "uint", "optional": false, "format": 64}
        }, {
            "name": "accuredInterest",
            "type": {"kind": "simple", "type": "uint", "optional": false, "format": 64}
        }, {
            "name": "merchantInterest",
            "type": {"kind": "simple", "type": "uint", "optional": false, "format": 64}
        }, {"name": "acceptJettons", "type": {"kind": "dict", "key": "address", "value": "address"}}]
    },
    {
        "name": "TokenRecievedMessage",
        "header": 1935855772,
        "fields": [{
            "name": "queryId",
            "type": {"kind": "simple", "type": "uint", "optional": false, "format": 64}
        }, {
            "name": "amount",
            "type": {"kind": "simple", "type": "uint", "optional": false, "format": "coins"}
        }, {"name": "from", "type": {"kind": "simple", "type": "address", "optional": false}}, {
            "name": "type",
            "type": {"kind": "simple", "type": "uint", "optional": false, "format": 8}
        }, {"name": "data", "type": {"kind": "simple", "type": "slice", "optional": false, "format": "remainder"}}]
    },
    {
        "name": "Mint",
        "header": 4235234258,
        "fields": [{
            "name": "amount",
            "type": {"kind": "simple", "type": "int", "optional": false, "format": 257}
        }, {"name": "receiver", "type": {"kind": "simple", "type": "address", "optional": false}}]
    },
    {
        "name": "JettonData",
        "header": null,
        "fields": [{
            "name": "totalSupply",
            "type": {"kind": "simple", "type": "int", "optional": false, "format": 257}
        }, {"name": "mintable", "type": {"kind": "simple", "type": "bool", "optional": false}}, {
            "name": "owner",
            "type": {"kind": "simple", "type": "address", "optional": false}
        }, {"name": "content", "type": {"kind": "simple", "type": "cell", "optional": false}}, {
            "name": "walletCode",
            "type": {"kind": "simple", "type": "cell", "optional": false}
        }]
    },
    {
        "name": "JettonWalletData",
        "header": null,
        "fields": [{
            "name": "balance",
            "type": {"kind": "simple", "type": "int", "optional": false, "format": 257}
        }, {"name": "owner", "type": {"kind": "simple", "type": "address", "optional": false}}, {
            "name": "master",
            "type": {"kind": "simple", "type": "address", "optional": false}
        }, {"name": "walletCode", "type": {"kind": "simple", "type": "cell", "optional": false}}]
    },
    {
        "name": "TokenTransfer",
        "header": 260734629,
        "fields": [{
            "name": "queryId",
            "type": {"kind": "simple", "type": "uint", "optional": false, "format": 64}
        }, {
            "name": "amount",
            "type": {"kind": "simple", "type": "uint", "optional": false, "format": "coins"}
        }, {
            "name": "destination",
            "type": {"kind": "simple", "type": "address", "optional": false}
        }, {
            "name": "response_destination",
            "type": {"kind": "simple", "type": "address", "optional": false}
        }, {
            "name": "custom_payload",
            "type": {"kind": "simple", "type": "cell", "optional": true}
        }, {
            "name": "forward_ton_amount",
            "type": {"kind": "simple", "type": "uint", "optional": false, "format": "coins"}
        }, {
            "name": "forward_payload",
            "type": {"kind": "simple", "type": "slice", "optional": false, "format": "remainder"}
        }]
    },
    {
        "name": "TokenTransferInternal",
        "header": 395134233,
        "fields": [{
            "name": "queryId",
            "type": {"kind": "simple", "type": "uint", "optional": false, "format": 64}
        }, {
            "name": "amount",
            "type": {"kind": "simple", "type": "uint", "optional": false, "format": "coins"}
        }, {
            "name": "from",
            "type": {"kind": "simple", "type": "address", "optional": false}
        }, {
            "name": "response_destination",
            "type": {"kind": "simple", "type": "address", "optional": false}
        }, {
            "name": "forward_ton_amount",
            "type": {"kind": "simple", "type": "uint", "optional": false, "format": "coins"}
        }, {
            "name": "forward_payload",
            "type": {"kind": "simple", "type": "slice", "optional": false, "format": "remainder"}
        }]
    },
    {
        "name": "TokenNotification",
        "header": 1935855772,
        "fields": [{
            "name": "queryId",
            "type": {"kind": "simple", "type": "uint", "optional": false, "format": 64}
        }, {
            "name": "amount",
            "type": {"kind": "simple", "type": "uint", "optional": false, "format": "coins"}
        }, {
            "name": "from",
            "type": {"kind": "simple", "type": "address", "optional": false}
        }, {
            "name": "forward_payload",
            "type": {"kind": "simple", "type": "slice", "optional": false, "format": "remainder"}
        }]
    },
    {
        "name": "TokenBurn",
        "header": 1499400124,
        "fields": [{
            "name": "queryId",
            "type": {"kind": "simple", "type": "uint", "optional": false, "format": 64}
        }, {
            "name": "amount",
            "type": {"kind": "simple", "type": "uint", "optional": false, "format": "coins"}
        }, {
            "name": "owner",
            "type": {"kind": "simple", "type": "address", "optional": false}
        }, {"name": "response_destination", "type": {"kind": "simple", "type": "address", "optional": false}}]
    },
    {
        "name": "TokenBurnNotification",
        "header": 2078119902,
        "fields": [{
            "name": "queryId",
            "type": {"kind": "simple", "type": "uint", "optional": false, "format": 64}
        }, {
            "name": "amount",
            "type": {"kind": "simple", "type": "uint", "optional": false, "format": "coins"}
        }, {
            "name": "owner",
            "type": {"kind": "simple", "type": "address", "optional": false}
        }, {"name": "response_destination", "type": {"kind": "simple", "type": "address", "optional": true}}]
    },
    {
        "name": "TokenExcesses",
        "header": 3576854235,
        "fields": [{"name": "queryId", "type": {"kind": "simple", "type": "uint", "optional": false, "format": 64}}]
    },
    {
        "name": "TokenUpdateContent",
        "header": 2937889386,
        "fields": [{"name": "content", "type": {"kind": "simple", "type": "cell", "optional": false}}]
    },
]

const JettonDefaultWallet_getters: ABIGetter[] = [
    {
        "name": "msgValue",
        "arguments": [{"name": "value", "type": {"kind": "simple", "type": "int", "optional": false, "format": 257}}],
        "returnType": {"kind": "simple", "type": "int", "optional": false, "format": 257}
    },
    {
        "name": "get_wallet_data",
        "arguments": [],
        "returnType": {"kind": "simple", "type": "JettonWalletData", "optional": false}
    },
]

const JettonDefaultWallet_receivers: ABIReceiver[] = [
    {"receiver": "internal", "message": {"kind": "typed", "type": "TokenTransfer"}},
    {"receiver": "internal", "message": {"kind": "typed", "type": "TokenTransferInternal"}},
    {"receiver": "internal", "message": {"kind": "typed", "type": "TokenBurn"}},
]

function loadTupleJettonWalletData(source: TupleReader) {
    let _balance = source.readBigNumber();
    let _owner = source.readAddress();
    let _master = source.readAddress();
    let _walletCode = source.readCell();
    return {
        $$type: 'JettonWalletData' as const,
        balance: _balance,
        owner: _owner,
        master: _master,
        walletCode: _walletCode
    };
}


type JettonDefaultWallet_init_args = {
    $$type: 'JettonDefaultWallet_init_args';
    master: Address;
    owner: Address;
}

function initJettonDefaultWallet_init_args(src: JettonDefaultWallet_init_args) {
    return (builder: Builder) => {
        let b_0 = builder;
        b_0.storeAddress(src.master);
        b_0.storeAddress(src.owner);
    };
}

async function JettonDefaultWallet_init(master: Address, owner: Address, code: Cell, system: Cell) {
    let builder = beginCell();
    builder.storeRef(system);
    builder.storeUint(0, 1);
    initJettonDefaultWallet_init_args({$$type: 'JettonDefaultWallet_init_args', master, owner})(builder);
    const __data = builder.endCell();
    return {code: code, data: __data};
}


export class JettonChildWallet implements Contract {

    static async fromInit(master: Address, owner: Address, code: Cell, system: Cell) {
        const init = await JettonDefaultWallet_init(master, owner, code, system);
        const address = contractAddress(0, init);
        return new JettonChildWallet(address, init);
    }

    static fromAddress(address: Address) {
        return new JettonChildWallet(address);
    }

    readonly address: Address;
    readonly init?: { code: Cell, data: Cell };
    readonly abi: ContractABI = {
        types: JettonDefaultWallet_types,
        getters: JettonDefaultWallet_getters,
        receivers: JettonDefaultWallet_receivers,
        errors: JettonDefaultWallet_errors,
    };

    private constructor(address: Address, init?: { code: Cell, data: Cell }) {
        this.address = address;
        this.init = init;
    }

    async send(provider: ContractProvider, via: Sender, args: {
        value: bigint,
        bounce?: boolean | null | undefined
    }, message: TokenTransfer) {

        let body: Cell | null = null;
        if (message && typeof message === 'object' && !(message instanceof Slice) && message.$$type === 'TokenTransfer') {
            body = beginCell().store(storeTokenTransfer(message)).endCell();
        }
        if (body === null) {
            throw new Error('Invalid message type');
        }

        await provider.internal(via, {...args, body: body});

    }

    async getMsgValue(provider: ContractProvider, value: bigint) {
        let builder = new TupleBuilder();
        builder.writeNumber(value);
        let source = (await provider.get('msgValue', builder.build())).stack;
        let result = source.readBigNumber();
        return result;
    }

    async getGetWalletData(provider: ContractProvider) {
        let builder = new TupleBuilder();
        let source = (await provider.get('get_wallet_data', builder.build())).stack;
        const result = loadTupleJettonWalletData(source);
        return result;
    }

}


const SampleJetton_errors: { [key: number]: { message: string } } = {
    2: {message: `Stack underflow`},
    3: {message: `Stack overflow`},
    4: {message: `Integer overflow`},
    5: {message: `Integer out of expected range`},
    6: {message: `Invalid opcode`},
    7: {message: `Type check error`},
    8: {message: `Cell overflow`},
    9: {message: `Cell underflow`},
    10: {message: `Dictionary error`},
    13: {message: `Out of gas error`},
    32: {message: `Method ID not found`},
    34: {message: `Action is invalid or not supported`},
    37: {message: `Not enough TON`},
    38: {message: `Not enough extra-currencies`},
    128: {message: `Null reference exception`},
    129: {message: `Invalid serialization prefix`},
    130: {message: `Invalid incoming message`},
    131: {message: `Constraints error`},
    132: {message: `Access denied`},
    133: {message: `Contract stopped`},
    134: {message: `Invalid argument`},
    135: {message: `Code of a contract was not found`},
    136: {message: `Invalid address`},
    137: {message: `Masterchain support is not enabled for this contract`},
    3734: {message: `Not Owner`},
    4159: {message: `Invalid value!!`},
    4429: {message: `Invalid sender`},
    6898: {message: `The total supply will be overlapping.`},
    12914: {message: `Offer is canceled`},
    17894: {message: `Invalid amount sent`},
    18668: {message: `Can't Mint Anymore`},
    22242: {message: `Invalid NFT address recieved!`},
    28249: {message: `Not an owner of the offer`},
    33502: {message: `Invalid sender: parent required`},
    33546: {message: `Invalid jetton sender`},
    34910: {message: `Not enough money`},
    38366: {message: `TON not acceptable`},
    42708: {message: `Invalid sender!`},
    43422: {message: `Invalid value - Burn`},
    43607: {message: `Invalid message type`},
    44194: {message: `Invalid sender: issuer required`},
    48926: {message: `When sending tons, jetton should be null`},
    50979: {message: `Offer not found`},
    53874: {message: `NFT not received`},
    59515: {message: `Jetton not found`},
    61809: {message: `not enough tons`},
    62497: {message: `Invalid jetton sent`},
    62972: {message: `Invalid balance`},
}

const SampleJetton_types: ABIType[] = [
    {
        "name": "StateInit",
        "header": null,
        "fields": [{"name": "code", "type": {"kind": "simple", "type": "cell", "optional": false}}, {
            "name": "data",
            "type": {"kind": "simple", "type": "cell", "optional": false}
        }]
    },
    {
        "name": "Context",
        "header": null,
        "fields": [{
            "name": "bounced",
            "type": {"kind": "simple", "type": "bool", "optional": false}
        }, {"name": "sender", "type": {"kind": "simple", "type": "address", "optional": false}}, {
            "name": "value",
            "type": {"kind": "simple", "type": "int", "optional": false, "format": 257}
        }, {"name": "raw", "type": {"kind": "simple", "type": "slice", "optional": false}}]
    },
    {
        "name": "SendParameters",
        "header": null,
        "fields": [{"name": "bounce", "type": {"kind": "simple", "type": "bool", "optional": false}}, {
            "name": "to",
            "type": {"kind": "simple", "type": "address", "optional": false}
        }, {
            "name": "value",
            "type": {"kind": "simple", "type": "int", "optional": false, "format": 257}
        }, {
            "name": "mode",
            "type": {"kind": "simple", "type": "int", "optional": false, "format": 257}
        }, {"name": "body", "type": {"kind": "simple", "type": "cell", "optional": true}}, {
            "name": "code",
            "type": {"kind": "simple", "type": "cell", "optional": true}
        }, {"name": "data", "type": {"kind": "simple", "type": "cell", "optional": true}}]
    },
    {
        "name": "Deploy",
        "header": 2490013878,
        "fields": [{"name": "queryId", "type": {"kind": "simple", "type": "uint", "optional": false, "format": 64}}]
    },
    {
        "name": "DeployOk",
        "header": 2952335191,
        "fields": [{"name": "queryId", "type": {"kind": "simple", "type": "uint", "optional": false, "format": 64}}]
    },
    {
        "name": "FactoryDeploy",
        "header": 1829761339,
        "fields": [{
            "name": "queryId",
            "type": {"kind": "simple", "type": "uint", "optional": false, "format": 64}
        }, {"name": "cashback", "type": {"kind": "simple", "type": "address", "optional": false}}]
    },
    {
        "name": "ChangeOwner",
        "header": 2174598809,
        "fields": [{
            "name": "queryId",
            "type": {"kind": "simple", "type": "uint", "optional": false, "format": 64}
        }, {"name": "newOwner", "type": {"kind": "simple", "type": "address", "optional": false}}]
    },
    {
        "name": "ChangeOwnerOk",
        "header": 846932810,
        "fields": [{
            "name": "queryId",
            "type": {"kind": "simple", "type": "uint", "optional": false, "format": 64}
        }, {"name": "newOwner", "type": {"kind": "simple", "type": "address", "optional": false}}]
    },
    {
        "name": "Loan",
        "header": null,
        "fields": [{
            "name": "wantAmount",
            "type": {"kind": "simple", "type": "uint", "optional": false, "format": 64}
        }, {
            "name": "days",
            "type": {"kind": "simple", "type": "uint", "optional": false, "format": 8}
        }, {
            "name": "dayInterest",
            "type": {"kind": "simple", "type": "uint", "optional": false, "format": 64}
        }, {"name": "jetton", "type": {"kind": "simple", "type": "address", "optional": true}}]
    },
    {
        "name": "InitLoan",
        "header": 19415667,
        "fields": [{
            "name": "loan",
            "type": {"kind": "simple", "type": "Loan", "optional": false}
        }, {"name": "nftAddress", "type": {"kind": "simple", "type": "address", "optional": false}}, {
            "name": "owner",
            "type": {"kind": "simple", "type": "address", "optional": false}
        }]
    },
    {"name": "StartMsg", "header": 135406920, "fields": []},
    {
        "name": "Offer",
        "header": 2645385394,
        "fields": [{"name": "offerLoan", "type": {"kind": "simple", "type": "Loan", "optional": false}}]
    },
    {
        "name": "OfferLoan",
        "header": null,
        "fields": [{"name": "offer", "type": {"kind": "simple", "type": "Loan", "optional": false}}, {
            "name": "owner",
            "type": {"kind": "simple", "type": "address", "optional": false}
        }]
    },
    {"name": "WithdrawNFTNotRepayed", "header": 311579522, "fields": []},
    {"name": "CancelLoan", "header": 311514246, "fields": []},
    {"name": "RedeemMessage", "header": 558310259, "fields": []},
    {
        "name": "CancelOffer",
        "header": 1012362342,
        "fields": [{"name": "offerIndex", "type": {"kind": "simple", "type": "int", "optional": false, "format": 257}}]
    },
    {
        "name": "StartOfferIndex",
        "header": 1825726764,
        "fields": [{"name": "offerIndex", "type": {"kind": "simple", "type": "int", "optional": false, "format": 257}}]
    },
    {
        "name": "ForwardNftPayload",
        "header": null,
        "fields": [{"name": "loan", "type": {"kind": "simple", "type": "Loan", "optional": false}}]
    },
    {
        "name": "SetAcceptableJettons",
        "header": 674633801,
        "fields": [{"name": "jettons", "type": {"kind": "dict", "key": "address", "value": "address"}}]
    },
    {
        "name": "OwnershipAssignedMaster",
        "header": 85167505,
        "fields": [{
            "name": "queryId",
            "type": {"kind": "simple", "type": "uint", "optional": false, "format": 64}
        }, {"name": "prevOwner", "type": {"kind": "simple", "type": "address", "optional": false}}, {
            "name": "bite",
            "type": {"kind": "simple", "type": "bool", "optional": false}
        }, {"name": "forwardPayload", "type": {"kind": "simple", "type": "ForwardNftPayload", "optional": false}}]
    },
    {
        "name": "OwnershipAssigned",
        "header": 85167505,
        "fields": [{
            "name": "queryId",
            "type": {"kind": "simple", "type": "uint", "optional": false, "format": 64}
        }, {
            "name": "prevOwner",
            "type": {"kind": "simple", "type": "address", "optional": false}
        }, {
            "name": "forwardPayload",
            "type": {"kind": "simple", "type": "slice", "optional": false, "format": "remainder"}
        }]
    },
    {
        "name": "Transfer",
        "header": 1607220500,
        "fields": [{
            "name": "queryId",
            "type": {"kind": "simple", "type": "uint", "optional": false, "format": 64}
        }, {
            "name": "newOwner",
            "type": {"kind": "simple", "type": "address", "optional": false}
        }, {
            "name": "responseDestination",
            "type": {"kind": "simple", "type": "address", "optional": false}
        }, {
            "name": "customPayload",
            "type": {"kind": "simple", "type": "bool", "optional": false}
        }, {
            "name": "forwardAmount",
            "type": {"kind": "simple", "type": "uint", "optional": false, "format": "coins"}
        }, {
            "name": "hasPayload",
            "type": {"kind": "simple", "type": "bool", "optional": false}
        }, {
            "name": "forwardPayload",
            "type": {"kind": "simple", "type": "slice", "optional": false, "format": "remainder"}
        }]
    },
    {"name": "ExcessMsg", "header": 3576854235, "fields": []},
    {
        "name": "LoanData",
        "header": null,
        "fields": [{"name": "nft", "type": {"kind": "simple", "type": "address", "optional": true}}, {
            "name": "owner",
            "type": {"kind": "simple", "type": "address", "optional": false}
        }, {"name": "stopped", "type": {"kind": "simple", "type": "bool", "optional": false}}, {
            "name": "parent",
            "type": {"kind": "simple", "type": "address", "optional": false}
        }, {"name": "started", "type": {"kind": "simple", "type": "bool", "optional": false}}, {
            "name": "loanIssuer",
            "type": {"kind": "simple", "type": "address", "optional": true}
        }, {"name": "activeLoan", "type": {"kind": "simple", "type": "Loan", "optional": true}}, {
            "name": "masterIndex",
            "type": {"kind": "simple", "type": "uint", "optional": false, "format": 64}
        }, {
            "name": "startTime",
            "type": {"kind": "simple", "type": "uint", "optional": false, "format": 64}
        }, {
            "name": "accuredInterest",
            "type": {"kind": "simple", "type": "uint", "optional": false, "format": 64}
        }, {
            "name": "merchantInterest",
            "type": {"kind": "simple", "type": "uint", "optional": false, "format": 64}
        }, {"name": "acceptJettons", "type": {"kind": "dict", "key": "address", "value": "address"}}]
    },
    {
        "name": "TokenRecievedMessage",
        "header": 1935855772,
        "fields": [{
            "name": "queryId",
            "type": {"kind": "simple", "type": "uint", "optional": false, "format": 64}
        }, {
            "name": "amount",
            "type": {"kind": "simple", "type": "uint", "optional": false, "format": "coins"}
        }, {"name": "from", "type": {"kind": "simple", "type": "address", "optional": false}}, {
            "name": "type",
            "type": {"kind": "simple", "type": "uint", "optional": false, "format": 8}
        }, {"name": "data", "type": {"kind": "simple", "type": "slice", "optional": false, "format": "remainder"}}]
    },
    {
        "name": "Mint",
        "header": 4235234258,
        "fields": [{
            "name": "amount",
            "type": {"kind": "simple", "type": "int", "optional": false, "format": 257}
        }, {"name": "receiver", "type": {"kind": "simple", "type": "address", "optional": false}}]
    },
    {
        "name": "JettonData",
        "header": null,
        "fields": [{
            "name": "totalSupply",
            "type": {"kind": "simple", "type": "int", "optional": false, "format": 257}
        }, {"name": "mintable", "type": {"kind": "simple", "type": "bool", "optional": false}}, {
            "name": "owner",
            "type": {"kind": "simple", "type": "address", "optional": false}
        }, {"name": "content", "type": {"kind": "simple", "type": "cell", "optional": false}}, {
            "name": "walletCode",
            "type": {"kind": "simple", "type": "cell", "optional": false}
        }]
    },
    {
        "name": "JettonWalletData",
        "header": null,
        "fields": [{
            "name": "balance",
            "type": {"kind": "simple", "type": "int", "optional": false, "format": 257}
        }, {"name": "owner", "type": {"kind": "simple", "type": "address", "optional": false}}, {
            "name": "master",
            "type": {"kind": "simple", "type": "address", "optional": false}
        }, {"name": "walletCode", "type": {"kind": "simple", "type": "cell", "optional": false}}]
    },
    {
        "name": "TokenTransfer",
        "header": 260734629,
        "fields": [{
            "name": "queryId",
            "type": {"kind": "simple", "type": "uint", "optional": false, "format": 64}
        }, {
            "name": "amount",
            "type": {"kind": "simple", "type": "uint", "optional": false, "format": "coins"}
        }, {
            "name": "destination",
            "type": {"kind": "simple", "type": "address", "optional": false}
        }, {
            "name": "response_destination",
            "type": {"kind": "simple", "type": "address", "optional": false}
        }, {
            "name": "custom_payload",
            "type": {"kind": "simple", "type": "cell", "optional": true}
        }, {
            "name": "forward_ton_amount",
            "type": {"kind": "simple", "type": "uint", "optional": false, "format": "coins"}
        }, {
            "name": "forward_payload",
            "type": {"kind": "simple", "type": "slice", "optional": false, "format": "remainder"}
        }]
    },
    {
        "name": "TokenTransferInternal",
        "header": 395134233,
        "fields": [{
            "name": "queryId",
            "type": {"kind": "simple", "type": "uint", "optional": false, "format": 64}
        }, {
            "name": "amount",
            "type": {"kind": "simple", "type": "uint", "optional": false, "format": "coins"}
        }, {
            "name": "from",
            "type": {"kind": "simple", "type": "address", "optional": false}
        }, {
            "name": "response_destination",
            "type": {"kind": "simple", "type": "address", "optional": false}
        }, {
            "name": "forward_ton_amount",
            "type": {"kind": "simple", "type": "uint", "optional": false, "format": "coins"}
        }, {
            "name": "forward_payload",
            "type": {"kind": "simple", "type": "slice", "optional": false, "format": "remainder"}
        }]
    },
    {
        "name": "TokenNotification",
        "header": 1935855772,
        "fields": [{
            "name": "queryId",
            "type": {"kind": "simple", "type": "uint", "optional": false, "format": 64}
        }, {
            "name": "amount",
            "type": {"kind": "simple", "type": "uint", "optional": false, "format": "coins"}
        }, {
            "name": "from",
            "type": {"kind": "simple", "type": "address", "optional": false}
        }, {
            "name": "forward_payload",
            "type": {"kind": "simple", "type": "slice", "optional": false, "format": "remainder"}
        }]
    },
    {
        "name": "TokenBurn",
        "header": 1499400124,
        "fields": [{
            "name": "queryId",
            "type": {"kind": "simple", "type": "uint", "optional": false, "format": 64}
        }, {
            "name": "amount",
            "type": {"kind": "simple", "type": "uint", "optional": false, "format": "coins"}
        }, {
            "name": "owner",
            "type": {"kind": "simple", "type": "address", "optional": false}
        }, {"name": "response_destination", "type": {"kind": "simple", "type": "address", "optional": false}}]
    },
    {
        "name": "TokenBurnNotification",
        "header": 2078119902,
        "fields": [{
            "name": "queryId",
            "type": {"kind": "simple", "type": "uint", "optional": false, "format": 64}
        }, {
            "name": "amount",
            "type": {"kind": "simple", "type": "uint", "optional": false, "format": "coins"}
        }, {
            "name": "owner",
            "type": {"kind": "simple", "type": "address", "optional": false}
        }, {"name": "response_destination", "type": {"kind": "simple", "type": "address", "optional": true}}]
    },
    {
        "name": "TokenExcesses",
        "header": 3576854235,
        "fields": [{"name": "queryId", "type": {"kind": "simple", "type": "uint", "optional": false, "format": 64}}]
    },
    {
        "name": "TokenUpdateContent",
        "header": 2937889386,
        "fields": [{"name": "content", "type": {"kind": "simple", "type": "cell", "optional": false}}]
    },
]

const SampleJetton_getters: ABIGetter[] = [
    {
        "name": "get_jetton_data",
        "arguments": [],
        "returnType": {"kind": "simple", "type": "JettonData", "optional": false}
    },
    {
        "name": "get_wallet_address",
        "arguments": [{"name": "owner", "type": {"kind": "simple", "type": "address", "optional": false}}],
        "returnType": {"kind": "simple", "type": "address", "optional": false}
    },
    {"name": "owner", "arguments": [], "returnType": {"kind": "simple", "type": "address", "optional": false}},
]

const SampleJetton_receivers: ABIReceiver[] = [
    {"receiver": "internal", "message": {"kind": "typed", "type": "Mint"}},
    {"receiver": "internal", "message": {"kind": "text", "text": "Mint: 100"}},
    {"receiver": "internal", "message": {"kind": "text", "text": "Owner: MintClose"}},
    {"receiver": "internal", "message": {"kind": "typed", "type": "TokenUpdateContent"}},
    {"receiver": "internal", "message": {"kind": "typed", "type": "TokenBurnNotification"}},
]

function loadTupleJettonData(source: TupleReader) {
    let _totalSupply = source.readBigNumber();
    let _mintable = source.readBoolean();
    let _owner = source.readAddress();
    let _content = source.readCell();
    let _walletCode = source.readCell();
    return {
        $$type: 'JettonData' as const,
        totalSupply: _totalSupply,
        mintable: _mintable,
        owner: _owner,
        content: _content,
        walletCode: _walletCode
    };
}


export class JettonMaster implements Contract {


    static fromAddress(address: Address) {
        return new JettonMaster(address);
    }

    readonly address: Address;
    readonly init?: { code: Cell, data: Cell };
    readonly abi: ContractABI = {
        types: SampleJetton_types,
        getters: SampleJetton_getters,
        receivers: SampleJetton_receivers,
        errors: SampleJetton_errors,
    };

    private constructor(address: Address, init?: { code: Cell, data: Cell }) {
        this.address = address;
        this.init = init;
    }

    async getGetJettonData(provider: ContractProvider) {
        let builder = new TupleBuilder();
        let source = (await provider.get('get_jetton_data', builder.build())).stack;
        const result = loadTupleJettonData(source);
        return result;
    }

    async getGetWalletAddress(provider: ContractProvider, owner: Address) {
        let builder = new TupleBuilder();
        builder.writeAddress(owner);
        let source = (await provider.get('get_wallet_address', builder.build())).stack;
        let result = source.readAddress();
        return result;
    }

    async getOwner(provider: ContractProvider) {
        let builder = new TupleBuilder();
        let source = (await provider.get('owner', builder.build())).stack;
        let result = source.readAddress();
        return result;
    }

}