import {useEffect, useState} from "preact/hooks";
import Dialog from "../Dialogs/Dialog.tsx";
import LoadingSpinner from "../utils/LoadingSpinner.tsx";
import {toast} from "react-toastify";
import {NotEnoughBalanceError} from "../../contracts/main.ts";


export default function ApproveTransactionDialog() {
    const [show, loading] = useDialogOpenedShown();
    if (!show) return <></>
    return (
        <Dialog onClose={() => {
        }} actionBtns={[]} noClose={true}>
            {
                loading && <>
                    <h2>Processing by blockchain...</h2>
                    <p>Please wait a little...</p>
                    <div style={{textAlign: 'center'}}>
                        <LoadingSpinner size={70} color="#fff"/>
                    </div>
                </>
            }
            {
                !loading && <>
                    <h2>Approve the transaction in your wallet...</h2>

                    <br/>
                    <p>We've send you notification in your wallet. <br/>To continue, you have to approve the transaction
                        using
                        it and wait a little for it to be processed by TON blockchain...</p>
                </>
            }

        </Dialog>
    )
}


let shown: boolean = false,
    loading: boolean = false;

const listeners: ((result: unknown) => unknown)[] = [];


function useDialogOpenedShown() {
    const [shownNow, setShownNow] = useState(shown);
    const [loadingNow, setLoadingNow] = useState(loading);
    useEffect(() => {
        const ev = () => {
            setShownNow(shown);
            setLoadingNow(loading);
        }
        listeners.push(ev);
        return () => {
            const i = listeners.indexOf(ev);
            if (i !== -1) listeners.splice(i, 1);
        }
    }, []);


    return [shownNow, loadingNow];
}

export async function showApproveDialogLoading<T>(p: (() => Promise<T>) | Promise<T>): Promise<T> {
    loading = true;
    shown = true;
    for (const l of listeners) l(p);
    try {
        const result: T = !(p instanceof Promise) ? (await p()) : await p;
        return result;
    } catch (e) {
        showToastError(e);
        throw e;
    } finally {
        loading = false;
        shown = false;
        for (const l of listeners) l(null);
    }
}

export async function showApproveDialog<T>(p: (() => Promise<T>) | Promise<T>): Promise<T> {
    shown = true;
    loading = false;
    for (const l of listeners) l(p);
    try {
        const result: T = await (p instanceof Promise ? p : p());
        return result;
    } catch (e) {
        showToastError(e);
        throw e;
    } finally {
        shown = false;
        loading = false;
        for (const l of listeners) l(null);
    }
}

function showToastError(e: Error | unknown) {

    if (e instanceof Error) {
        if(e instanceof TypeError && e.message.includes('base64url')){
            toast.error(<>Error: <b>Transaction was rejected by user</b></>, {role: 'error'});
        }
        else toast.error(<>Error: <b>{((e as Error)).message}</b></>, {role: 'error'});
    }
    else toast.error(<>Error: <b>{e+""}</b></>, {role: 'error'});


}