import {OneBalance, TonApiJettonGetAnswer, TonApiNFT, TonApiOneNFT} from "./interfaces.ts";
import {Address} from "@ton/core";

const HOST = import.meta.env.VITE_TONAPI_HOST as string;


export enum Currency {
    RUB = 'rub',
    TON = 'ton',
    USD = 'usd'
}

class TonApi {
    async getNFTS(owner: string) {
        return this.method<{ nft_items: TonApiNFT[] }>(`/accounts/${owner}/nfts`, 'GET', {
            limit: 1000,
            offset: 0,
            indirect_ownership: false
        })
    }

    async getManyNfts(addresses: (string|Address)[]) {
        return this.method<{ nft_items: TonApiNFT[] }>(`/nfts/_bulk`, 'POST', {
            account_ids: addresses.map(e=>e.toString())
        })
    }

    async getNFTById(address: string) {
        return this.method<TonApiOneNFT>(`/nfts/${address}`, 'GET', {})
    }


    private async method<T>(url: string, method: 'GET' | 'POST', params: any) {
        return fetch(`${HOST}/v2${url}?` + (method === 'GET' ? new URLSearchParams(params) : ''), {
            method,
            body: method === 'POST' ? JSON.stringify(params) : undefined,
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(e => e.json()) as Promise<T>;
    }

    async getJettonByAddress(address: string): Promise<TonApiJettonGetAnswer> {
        return this.method<TonApiJettonGetAnswer>(`/jettons/${address}`, 'GET', {});
    }


    async getTokenBalanceOf(address: string, currencies: Currency[]) {
        return this.method<{ balances: OneBalance[] }>(`/accounts/${address}/jettons`, 'GET', {
            currencies: currencies.join(',')
        })
    }

    async getTonPrice(currencies: Currency[]): Promise<number> {
        return this.method<{ rates: { TON: { prices: { USD: number } } } }>(`/rates`, 'GET', {
            tokens: 'ton',
            currencies: currencies.join(',')
        }).then(e => e.rates.TON.prices.USD);
    }

    async getBalanceOf(address: string): Promise<bigint> {
        return this.method<{ balance: number }>(`/accounts/${address}`, 'GET', {}).then(e => BigInt(e.balance));
    }
}

export default new TonApi();