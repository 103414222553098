import Styles from "./Header.module.scss";
import TonConnectBtn from "../buttons/TonConnectBtn.tsx";
import Button from "../buttons/Button.tsx";
import BackIcon from "../Icons.tsx";
import {disconnectUserWallet, showTonConnectLoginPage, useUserAddress} from "../../tonConnect.ts";
import NavButton from "../buttons/NavButton.tsx";
import ButtonRound from "../buttons/ButtonRound.tsx";
export default function Header() {
    const address = useUserAddress();
    // const locationNow = useLocation();
    return (
        <>
            <header className={Styles.header}>
                <div onClick={()=>history.back()} className={Styles.backIcon}><BackIcon size={40} /></div>
                <div className={Styles.center}>
                    <NavButton href="/client">Get a loan</NavButton>
                    <NavButton href="/jeweler">Give a loan</NavButton>
                </div>
                {!!address && <ButtonRound class={Styles.last} onClick={disconnectUserWallet}>Disconnect</ButtonRound>}
                {!address && <TonConnectBtn class={Styles.last} onClick={showTonConnectLoginPage}/>}
            </header>
        </>
    )
}