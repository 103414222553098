import {useParams} from "react-router-dom";
import {TonApiOneNFT} from "../../api/tonapi/interfaces.ts";
import {useEffect, useState} from "preact/hooks";
import TonApi from "../../api/tonapi/TonApi.ts";
import NftItemForLoan from "../../components/NftItemForLoan/NftItemForLoan.tsx";
import LoadingSpinner from "../../components/utils/LoadingSpinner.tsx";

export default function PawnNftPage(){
    const address = useParams().nft!;
    const [nftData, setNftData] = useState<TonApiOneNFT|null>(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        loadNFT(address).then(setNftData)
            .finally(()=>setLoading(false));
    }, []);
    return (
        <div>
            {loading && <div style={{textAlign: 'center'}}><LoadingSpinner size={50} color={"#fff"} /></div>}
            {!nftData && !loading && <div>NFT not found</div>}
            {nftData && !loading && <NftItemForLoan item={nftData} onRefresh={()=>{
                setLoading(true);
                loadNFT(address).then(setNftData)
                    .finally(()=>setLoading(false));
            }} />}
        </div>
    )
}


function loadNFT(address: string){
    return TonApi.getNFTById(address);
}