import Button from "./Button.tsx";
import ButtonRound from "./ButtonRound.tsx";
import {showTonConnectLoginPage} from "../../tonConnect.ts";
import {CSSProperties} from "preact/compat";


export default function TonConnectBtn({class: className, onClick=()=>showTonConnectLoginPage(), style}: {class?: string, onClick?: ()=>unknown, style?: CSSProperties}){

    return (
        <ButtonRound style={style} onClick={onClick} class={className??''}>CONNECT WALLET</ButtonRound>
    )
}