import DataBlock from "../../utils/DataBlock.tsx";
import Styles from "./DataBlockImage.module.scss"
import Button from "../../buttons/Button.tsx";
import {useParams} from "react-router-dom";
import {getGetgemsLink, getTonScanLink, getTonViewerLink} from "../../../config.ts";
import TonViewerIcon from "../../../static/tonviewer.png"
import GetGemsIcon from "../../../static/getgems.png"
import TonScanIcon from "../../../static/tonscan.svg"
import RefreshImg from "../../../static/refresh.svg"
import ImageIcon from "../../utils/ImageIcon.tsx";
export default function DataBlockImage({src, alt, onRefresh, nftAddress, loanAddress: loan}: { src: string, alt: string, onRefresh: () => unknown, nftAddress: string, loanAddress: string }) {
    return (
        <DataBlock class={Styles.block}>
            <div className={Styles.links}>
                <ImageIcon image={TonViewerIcon} alt="TonViewer" href={getTonViewerLink(loan!)}/>
                <ImageIcon image={TonScanIcon} alt="TonScan" href={getTonScanLink(loan!)}/>
                <ImageIcon image={GetGemsIcon} alt="GetGems" href={getGetgemsLink(nftAddress!)}/>
                <div onClick={onRefresh} class={Styles.refresh} tabindex={0}><img src={RefreshImg} width={42} height={42} alt="Refresh"/></div>
            </div>
            <img src={src} alt={alt} class={Styles.preview}/>
        </DataBlock>
    );
}


