import {useEffect, useState} from "preact/hooks";
import {LoanNFT} from "../../config.ts";
import JewelerNFT from "../../components/JewelerNFT/JewelerNFT.tsx";

import LendDialog from "../../components/Dialogs/LendDialog/LendDialog.tsx";
import LendDialogCustomized from "../../components/Dialogs/LendDialogCustomized/LendDialogCustomized.tsx";
import {showTonConnectLoginPage, useUserAddress} from "../../tonConnect.ts";
import Content from "../../components/utils/Content.module.tsx";
import useLoansApi from "../../api/loans/useLoansApi.ts";
import TonApi from "../../api/tonapi/TonApi.ts";
import {TonApiNFT} from "../../api/tonapi/interfaces.ts";
import {Address} from "@ton/core";
import {ILoanJson} from "../../api/loans/interfaces.ts";
import DataBlock from "../../components/utils/DataBlock.tsx";

export type NftData = (ILoanJson & { nft_item: TonApiNFT });
export default function JewelerPage() {
    const userAddress = useUserAddress();
    const [nfts, setNFTS] = useState<NftData[]>([]);
    const api = useLoansApi();

    useEffect(() => {
        api.get({limit: 20, skip: 0})
            .then(async ({loans}) => {
                const data = loans.map(e => e.nft);
                const nfts = await TonApi.getManyNfts(data)
                setNFTS(loans.map(e => ({
                    ...e,
                    nft_item: nfts.nft_items.find(nft => Address.parse(nft.address).equals(Address.parse(e.nft)))!
                })))
            })
    }, []);

    const [openedLend, setOpenedLend] = useState<LoanNFT | null>(null);
    const [openedCustomizedLend, setOpenedCustomizedLend] = useState<LoanNFT | null>(null);


    return (
        <Content>
            <DataBlock>
        <div></div>
            </DataBlock>
            <div style={{display: "flex", flexWrap: 'wrap'}}>
                {
                    nfts.map((nft) => (
                        <JewelerNFT nft={nft} onLend={async (nft) => {
                            if (!userAddress) return showTonConnectLoginPage();
                            setOpenedLend(nft);
                        }} onCustomize={(nft) => {
                            if (!userAddress) return showTonConnectLoginPage();
                            setOpenedCustomizedLend(nft);
                        }}/>
                    ))
                }
            </div>
            {!!openedCustomizedLend &&
                <LendDialogCustomized nft={openedCustomizedLend} onClose={() => setOpenedCustomizedLend(null)}
                                      onSuccess={v => {
                                          setOpenedLend(v);
                                          setOpenedCustomizedLend(null);
                                      }}/>}
            {!!openedLend && <LendDialog nft={openedLend} onClose={() => setOpenedLend(null)}/>}
        </Content>
    )
}