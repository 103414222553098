import Styles from "./Dialog.module.scss";
import {useEffect, useState} from "preact/hooks";
import {ReactNode, useRef} from "preact/compat";
import Button from "../buttons/Button.tsx";

export default function Dialog({onClose, children, actionBtns, noClose}: {
    onClose: () => void,
    children: any,
    actionBtns: ReactNode[],
    noClose?: boolean
}) {

    noClose ??= false;
    const dialogRef = useRef<HTMLDivElement>(null);
    const [closing, setClosing] = useState(false);
    useEffect(() => {
        const eventHandler = (e: KeyboardEvent) => {
            if(e.key === 'Escape') closeIT();
        }
        document.addEventListener('keyup', eventHandler)
        return () => void document.removeEventListener('keyup', eventHandler);
    }, []);
    useEffect(() => {
        if(!closing) document.body.style.overflow = 'hidden';
        return () => void (document.body.style.overflow = '');
    }, [closing]);
    return (
        <div class={Styles.bg} onClick={closeIT} ref={dialogRef}>
            <div class={Styles.content + (closing ? ' ' + Styles.closing : '')} onClick={e => {
                e.stopPropagation();
            }}>
                {children}
                {!noClose && <div className={Styles.closeBtn} onClick={closeIT}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                         fill="#e8eaed">
                        <path
                            d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/>
                    </svg>
                </div>}
                <div class={Styles.btns}>
                    {actionBtns}
                    {!noClose && <Button onClick={closeIT} autofocus={true}>Cancel</Button>}
                </div>
            </div>
        </div>
    );

    function closeIT() {
        if (noClose) return;
        setClosing(true);
        setTimeout(onClose, 200);
    }
}