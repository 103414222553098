import {ReactNode} from "preact/compat";
import Styles from "./Content.module.scss";

export default function Content({children}: {children: ReactNode}){
    return (
        <div class={Styles.content}>
            {children}
        </div>
    )
}

export function Data({children}: {children: ReactNode}){
    return (
        <div class={Styles.data}>
            {children}
        </div>
    )

}