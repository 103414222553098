import {LoanNFT} from "../../../config.ts";
import Dialog from "../Dialog.tsx";
import Button from "../../buttons/Button.tsx";
import {useRef, useState} from "preact/hooks";

export default function LendDialogCustomized({nft, onClose, onSuccess,onSuccessTitle='Preview loan', title='Customize loan'}: {
    nft: LoanNFT,
    onClose: () => void,
    onSuccess: (nft: LoanNFT) => unknown,
    title?: string,
    onSuccessTitle?:string
}) {
    const [newNFT, setNewNFT] = useState<LoanNFT>(nft);
    const refs = {
        interest: useRef<HTMLInputElement>(null),
        ton: useRef<HTMLInputElement>(null),
        days: useRef<HTMLInputElement>(null),
    }
    const [[startInterest, startTon, startDays], _] = useState([nft.want_interest, nft.want_amount_ton, nft.want_period_days]);
    return (
        <Dialog onClose={onClose} actionBtns={[
            <Button onClick={() => onSuccess(newNFT)} disabled={!validateIT()}>{onSuccessTitle}</Button>,
        ]}>
            <h2>{title}</h2>
            <form onSubmit={e => {
                e.preventDefault();
                onSuccess(newNFT)
            }}>
                <input type="number"
                       step={0.1}
                       placeholder={`Interest rate (${(startInterest*100).toFixed(2)}%)`}
                       onChange={onChange.bind(null, 'want_interest')}
                       min={1.00}
                       max={10}
                       style={{width: '100%', boxSizing: 'border-box'}}
                       ref={refs.interest}/>
                <br/>
                <input type="number"
                       style={{width: '100%', boxSizing: 'border-box'}}
                       placeholder={`TONs (${startTon})`}
                       min={0.1} max={1_000_000_000}
                       onChange={onChange.bind(null, 'want_amount_ton')} ref={refs.ton}/>
                <br/>
                <input type="number"
                       placeholder={`DAYs (${startDays})`}
                       min={1}
                       max={100}
                       style={{width: '100%', boxSizing: 'border-box'}}
                       onChange={onChange.bind(null, 'want_period_days')}
                       ref={refs.days}/>
            </form>
        </Dialog>
    );

    function onChange(k: keyof LoanNFT, e: Event) {
        setNewNFT({
            ...newNFT,
            [k]: k === 'want_interest' ? parseFloat((e.target as HTMLInputElement).value) / 100 : parseFloat((e.target as HTMLInputElement).value)
        });
    }

    function validateIT() {
        return newNFT.want_interest <= 10 && newNFT.want_interest >= 0.01 && newNFT.want_amount_ton >= 0.1 && newNFT.want_amount_ton <= 1_000_000_000 && newNFT.want_period_days >= 1 && newNFT.want_period_days <= 100;
    }
}