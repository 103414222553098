import {getTonViewerLink, IJetton} from "../../config.ts";
import Styles from "./OneJettonToWithdraw.module.scss";
import ImageIcon from "../utils/ImageIcon.tsx";
import TonViewerIcon from "../../static/tonviewer.png";

export type IJettonWithBalance = IJetton & { balance: string; amountUSD: number };

export default function OneJettonToWithdraw({isSelected, onClick, jetton: {balance, amountUSD, address, icon, title}}: {
    jetton: IJettonWithBalance,
    isSelected: boolean,
    onClick: ()=>unknown
}) {
    return (
        <div class={Styles.jettonToWithdraw + (isSelected ? ' ' + Styles.selected : '')} onClick={onClick}>
            <img src={icon} width={50} height={50}/>
            <span class={Styles.name}>{balance} {title}</span>
            <span class={Styles.usd}>(${amountUSD.toFixed(2)})</span>
            {!!address &&
                <ImageIcon image={TonViewerIcon} alt="TonViewer" href={getTonViewerLink(address!)}/>
            }
        </div>
    );
}