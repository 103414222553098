import Header from "../components/Header";
import Footer from "../components/Footer";
import ApproveTransactionDialog from "../components/ApproveTransactionDialog/ApproveTransactionDialog.tsx";


export function Main({children}: {children: any}){
    return (<>
        <Header />
        <main>{children}</main>
        <Footer />
        <ApproveTransactionDialog />
    </>)
}