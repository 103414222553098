import {LoanNFT} from "../../../config.ts";
import Dialog from "../Dialog.tsx";
import Button from "../../buttons/Button.tsx";
import Styles from "./LendDialog.module.scss";
import {useState} from "preact/hooks";
import LoadingSpinner from "../../utils/LoadingSpinner.tsx";
import {useUserAddress} from "../../../tonConnect.ts";
import {ReactNode} from "preact/compat";

export default function LendDialog({nft, onClose}: { nft: LoanNFT, onClose: () => void }) {
    const [loading, setLoading] = useState(false);
    const userAddress = useUserAddress();
    return (
        <Dialog onClose={onClose} actionBtns={[
            <Button onClick={lendIT}>{loading ? <LoadingSpinner size={18}/> : 'Lend'}</Button>,
        ] as ReactNode[]}>
            <h1>Lend NFT</h1>
            <p>Are you sure you want to lend this NFT?</p>
            <div class={Styles.horizontal}>
                <a href={nft.link} target="_blank">
                    <img src={nft.image} alt={nft.title} width={150} height={150} class={Styles.image}/>
                </a>
                <div>
                    <h2><a href={nft.link} target="_blank" class={Styles.link}>{nft.title}</a></h2>
                    <p>
                        {nft.description} <br/>
                        <b>Amount to give</b>: {nft.want_amount_ton} TON <br/>
                        <b>Duration</b>: {nft.want_period_days} days <br/>
                        <b>Interest rate</b>: {nft.want_interest.toFixed(2)}%
                    </p>
                </div>
            </div>
        </Dialog>
    );

    async function lendIT() {
        if (loading) return;
        setLoading(true);

        try {
            const balance = await fetch('https://toncenter.com/api/v2/getAddressInformation?address=' + userAddress)
                .then(e => e.json())
                .then(e => e.result.balance) as number;
            if (balance < nft.want_amount_ton) return alert('Not enough balance to lend this NFT');

        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    }
}