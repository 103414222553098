import {useEffect, useState} from "preact/hooks";
import TonApi from "../../api/tonapi/TonApi.ts";
import {TonApiNFT} from "../../api/tonapi/interfaces.ts";
import OneNFT from "./OneNFT.tsx";
import Styles from "./UserNFTs.module.scss";
import LoadingSpinner from "../utils/LoadingSpinner.tsx";
import DataBlock from "../utils/DataBlock.tsx";

export default function UserNFTs({owner}: { owner: string }) {

    const [nfts, setNFTS] = useState<TonApiNFT[]>([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        setNFTS([])
        fetchNFTs(owner).then(setNFTS).finally(() => setLoading(false));
    }, [owner]);
    return (
        <div class={Styles.nftsList}>
            {loading && <div style={{textAlign: 'center'}}> <LoadingSpinner size={50} color={"#fff"} /></div>}
            {!loading && !nfts.length && <DataBlock style={{width: '100%'}}>
                <h3 style={{textAlign: 'center', padding: '10px 0'}}>No NFTs found. <br/> Buy then on <a href={'https://getgems.io'} target={'_blank'}>getgems.io</a></h3>
            </DataBlock>}
            {
                !loading && !!nfts.length &&
                nfts.map((nft) => (
                    <OneNFT
                        address={nft.address}
                        preview={nft.previews[nft.previews.length-1]?.url??nft.metadata?.image}
                        name={nft.metadata?.name??""}
                    />))
            }
        </div>
    )
}


export async function fetchNFTs(owner: string){
    return TonApi.getNFTS(owner).then(e=>e.nft_items);
}