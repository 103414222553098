import DataBlock from "../../utils/DataBlock.tsx";
import Styles from "./DataBlockActions.module.scss"
import {showTonConnectLoginPage, useUserAddress} from "../../../tonConnect.ts";
import TonConnectBtn from "../../buttons/TonConnectBtn.tsx";
import {LoanState} from "../../../routes/client/Loan.tsx";
import {Address} from "@ton/core";
import Button from "../../buttons/Button.tsx";
import MakeOfferData, {InputNewOfferData} from "../MakeOffer/MakeOfferData.tsx";
import {TimeCounter} from "../../TimeCounter/TimeCounter.tsx";
import {useState} from "preact/hooks";
import {DefaultJettons, IJetton} from "../../../config.ts";
import SetJettonsState from "./DataBlockSetJettons.tsx";

export default function DataBlockActions(
    {
        state: {started, owner, stopped, startTime, issuer},
        onCancelLoan,
        onNewOffer,
        onRepay,
        onAcceptDefault,
        onNotRepayed,
        onSetJettons,
        hasJettons,
        conditions, defaultOfferData,
        jettons
    }: {
        jettons: IJetton[],
        state: LoanState,
        onCancelLoan: () => unknown,
        onNewOffer: (offer: InputNewOfferData) => unknown,
        onRepay: () => unknown,
        onAcceptDefault: () => unknown,
        onNotRepayed: () => unknown,
        conditions: {
            wantAmount: bigint;
            days: bigint;
            dayInterest: bigint;
        } | null,
        defaultOfferData: InputNewOfferData,
        onSetJettons: (jettons: (Address | null)[]) => unknown,
        hasJettons: boolean
    }) {
    const userAddress = useUserAddress();
    if (userAddress === null) return <ConnectWallet/>;
    if (owner.equals(Address.parse(userAddress))) return (
        <OwnerActions started={started}
                      onCancelLoan={onCancelLoan}
                      stopped={stopped}
                      hasJettons={hasJettons}
                      setJettons={onSetJettons}
                      isExpired={Date.now() > +startTime + Number(conditions?.days ?? 0n) * 24 * 60 * 60 * 1000}
                      endTime={new Date(+startTime + Number(conditions?.days ?? 0n) * 24 * 60 * 60 * 1000)}
                      onRepay={onRepay}/>
    );
    return <ActionsNotOwner
        stopped={stopped} started={started} onNewOffer={onNewOffer}
        onAcceptDefault={onAcceptDefault} onNotRepayed={onNotRepayed}
        isIssuer={!!issuer && !!userAddress && Address.parse(userAddress).equals(issuer)}
        isExpired={Date.now() > +startTime + Number(conditions?.days ?? 0n) * 24 * 60 * 60 * 1000}
        endTime={new Date(+startTime + Number(conditions?.days ?? 0n) * 24 * 60 * 60 * 1000)}
        defaultOfferData={defaultOfferData}
        jettons={jettons}
    />;

}

function ActionsNotOwner(
    {
        started,
        stopped,
        onNewOffer,
        onAcceptDefault,
        isIssuer,
        isExpired,
        onNotRepayed,
        endTime,
        defaultOfferData: def,
        jettons
    }: {
        started: boolean,
        stopped: boolean,
        onNewOffer: (data: InputNewOfferData) => unknown,
        onAcceptDefault: () => unknown,
        isIssuer: boolean,
        isExpired: boolean,
        onNotRepayed: () => unknown,
        endTime: Date,
        defaultOfferData: InputNewOfferData,
        jettons: IJetton[]
    }) {
    if (started && isIssuer) {
        return (
            <DataBlock class={Styles.red + ' ' + Styles.all}>
                <h4>Loan actions</h4>
                <div class={Styles.actionBtns}>
                    {
                        isExpired && <Button onClick={onNotRepayed}>Withdraw NFT</Button>
                    }
                    {
                        !isExpired && <><TimeCounter till={endTime}/> left</>
                    }
                </div>
            </DataBlock>

        )
    }
    if (started || stopped) return (
        <DataBlock class={Styles.red + ' ' + Styles.all}>
            <h2>Offer {started ? 'already started' : 'stopped'}!</h2>
            <p>Thus, you're not able to make loan offers.</p>
        </DataBlock>
    )
    return (
        <DataBlock class={Styles.all}>
            <MakeOfferData onSubmit={onNewOffer} onAcceptDefault={onAcceptDefault} default={def} jettons={jettons}/>
        </DataBlock>
    );
}

function OwnerActions({started, stopped, onCancelLoan, onRepay, hasJettons, setJettons, isExpired, endTime}: {
    started: boolean,
    stopped: boolean,
    onCancelLoan: () => unknown,
    onRepay: () => unknown,
    hasJettons: boolean,
    setJettons: (jettons: (null | Address)[]) => unknown,
    isExpired: boolean,
    endTime: Date

}) {
    if (stopped) {
        return (
            <DataBlock class={Styles.red + ' ' + Styles.all}>
                <h4>Loan actions</h4>
                <p>Loan is stopped, no actions available</p>
            </DataBlock>
        )
    }
    const [activeSetJettons, setActiveSetJettons] = useState(false);
    return (
        <DataBlock class={Styles.actions + ' ' + Styles.all}>
            {activeSetJettons ? <SetJettonsState onCancel={() => setActiveSetJettons(false)} onSuccess={setJettons}
                                                 jettonsStart={DefaultJettons}/> :
                <>
                    <h4>Loan actions</h4>
                    <div className={Styles.actionBtns}>
                        {!started && <Button onClick={onCancelLoan}>Cancel loan</Button>}
                        {started && !stopped && <Button onClick={onRepay}>Repay</Button>}
                        {!hasJettons && <Button onClick={() => setActiveSetJettons(true)}>Activate loan</Button>}
                        {
                            started && !isExpired && <><TimeCounter till={endTime}/> left</>
                        }
                    </div>
                </>
            }
        </DataBlock>
    )
}


function ConnectWallet() {
    return (
        <DataBlock class={Styles.red + ' ' + Styles.all}>
            <h2>Connect your wallet</h2>
            <p>To make loan offers, please connect your wallet.</p>
            <TonConnectBtn onClick={showTonConnectLoginPage} class=""/>
        </DataBlock>
    )
}