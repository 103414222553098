import UserNFTs from "../../components/UserNft/UserNFTs.tsx";
import {showTonConnectLoginPage, useUserAddress} from "../../tonConnect.ts";
import Content from "../../components/utils/Content.module.tsx";
import TonConnectBtn from "../../components/buttons/TonConnectBtn.tsx";


export default function ClientPage() {
    const userAddress = useUserAddress();
    return (
        <Content>
            {
                userAddress != null && <UserNFTs owner={userAddress}/>
            }
            {
                userAddress == null && <div style={{textAlign: 'center'}}>
                    Please connect your wallet ton continue <br/>
                <TonConnectBtn style={{margin: "0 auto"}}/>
                </div>
            }
        </Content>
    );
}