import {useEffect} from "preact/hooks";


export default function useHandleTabButton(onTabPress?: (e: KeyboardEvent)=>unknown, deps: unknown[]=[]){
    useEffect(() => {
        const handler = (e: KeyboardEvent)=>{
            if(e.key === 'Tab') {
                onTabPress?.(e);
            }
        }
        document.addEventListener('keydown', handler);
        return ()=>document.removeEventListener('keydown', handler);
    }, deps);
}