import Styles from "./Button.module.scss";
// import {Attributes} from "preact";
import {HTMLAttributes} from "react";
export default function Button(props: { children: any, onClick?: any, class?: string, disabled?: boolean, tag?: Function }&HTMLAttributes<HTMLButtonElement>) {
    const TagSTR = ((props.tag as unknown as string) ?? 'button') as 'button';
    const newProps = {...props};
    delete newProps.tag;
    delete newProps.children;
    delete newProps.onClick;
    delete newProps.class;
    delete newProps.disabled;
    return (
        <TagSTR {...newProps}  onClick={props.onClick} class={Styles.button + (props.class ? ' ' + props.class : '')} disabled={props.disabled===true}>
            {props.children}
        </TagSTR>
    );
}