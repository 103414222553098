import {ReactNode} from "preact/compat";
import Styles from "./OffersHead.module.scss"
import Styles2 from "./OffersAll.module.scss";
function OneCell({title}: { title: string }) {
    return (
        <div className={Styles.headerCell}><span>{title}</span>
            <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg"
                 className="accent-color svg desc ">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M0.671631 3.79289H6.32849L3.50006 6.62132L0.671631 3.79289Z" fill="currentColor"/>
            </svg>
        </div>
    )
}

export default function OffersHead() {
    return (
        <div className={Styles.header + ' '+Styles2.list}>
            <OneCell title="Principal" />
            <OneCell title="Dayly ARP" />
            <OneCell title="Duration" />
            <OneCell title="Repayment" />
            <OneCell title="Lender" />
            <OneCell title={"Actions"}/>
        </div>
    )
}