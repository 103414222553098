class XHRPolyfill extends XMLHttpRequest {

    constructor() {
        super();
    }

    get responseText(): string {
        const text = super.responseText;
        if (this.responseURL.endsWith('/jsonRPC')) {
            const responseJSON = JSON.parse(text);
            return JSON.stringify(polyFill(responseJSON));
        }
        return super.responseText;
    }
}

// Override the global XMLHttpRequest with the polyfill
(window as any).XMLHttpRequest = XHRPolyfill;


type StupidTupleElement = ({
    '@type': "tvm.stackEntryNumber",
    number: unknown
} | {
    '@type': "tvm.stackEntrySlice",
    slice: {
        '@type': "tvm.slice",
        bytes: string;
    }
} | {
    '@type': 'tvm.stackEntryList',
    list: unknown
} | {
    '@type': 'tvm.stackEntryCell',
    cell: string;
});
type ResponseStackItem = (['tuple', {
    '@type': "tvm.tuple",
    elements: StupidTupleElement[];
}] | ['cell' | 'num' | 'list', unknown])
type ProbablyNeededJson = {
    ok: boolean;
    result: {
        stack: ResponseStackItem[];
    }
}

function polyfillTupleElements(element: StupidTupleElement): StupidTupleElement {
    if (element["@type"] !== 'tvm.stackEntrySlice') return element;
    return {
        "@type": 'tvm.stackEntryCell',
        cell: element.slice.bytes
    }
}

function polyfillItems(item: ResponseStackItem): ResponseStackItem {
    if (item[0] !== 'tuple') return item;
    return [
        'tuple',
        {
            ...item[1],
            elements: item[1].elements.map(polyfillTupleElements)
        }
    ]
}

function polyFill(data: ProbablyNeededJson) {
    try {
        if (!data.ok || !data?.result?.stack) throw "Unsupported";
        data.result.stack = data.result.stack.map(polyfillItems);
    } catch (e) {
        console.warn(e);
    }
    return data;
}